import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Message from '../Common/Message'
import { FileUpload } from 'primereact/fileupload';
import './flexGrid.css';
import Loading from '../Loading';
import Moment from 'moment';
import Search from '../Common/Search'
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { APIUrl } from "../Common/Api";
class user extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '' },
            RoleId: '',
            CompanyList: [],
            RoleList: []

        }
    }
    componentWillMount() {
        this.clearData();
        this.fillCompany();
        this.fillRoles();
    }
      
    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
            }
    clearData = () => {
        let model = this.state.dataModel
        model['UserId'] = '';
        model['UserName'] = '';
        model['Password'] = '';
        model['ConfirmPassword'] = '';
        model['RoleId'] = ""
        model['CompanyId'] = ""
        this.setState({ dataModel: model, UserId: '' })
        

    }
    fillCompany = async () => {


        //let url = '/Search/Search'
        let url = APIUrl+'Search/Search'
        let data = new FormData
        data.append("Query", 5)
       // let data = { "Query": 5, "CompanyId":""}


        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
           // jsonData = jsonData;

            let list = [];
           // list.push({ 'label': 'Select', 'value': 'Select' })
            for (var i = 0; i < jsonData.length; i++) {

               

                list.push({ 'label': jsonData[i].CompanyName, 'value': jsonData[i].CompanyId })

            }


            this.setState({ CompanyList: list, loading: false })
                      

        }
        else {
            this.setState({ loading: false })

        }

    }
    fillRoles = async () => {


        let url = APIUrl +'Search/Search'
        let data = new FormData
        data.append("Query", 6)
        //let data = { "Query": 6, "CompanyId": "" }
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            //jsonData = JSON.parse(jsonData)
            let list = [];
                       for (var i = 0; i < jsonData.length; i++) {



                list.push({ 'label': jsonData[i].RoleName, 'value': jsonData[i].RoleId })

            }


            this.setState({ RoleList: list, loading: false })


        }
        else {
            this.setState({ loading: false })

        }

    }
    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: false })
        let updateModel = this.state.dataModel
        let UserId = 0;
        for (var item in selectedrow) {
            if (selectedrow.hasOwnProperty(item)) {

                if (item === "UserId") {
                    UserId = selectedrow[item];
                }

                else if (item === "RoleId") {
                    if (selectedrow[item] != null) {
                        let array = selectedrow[item].split(",");
                        let array1 = []
                        for (var i = 0; i < array.length; i++) {

                            array1.push(parseInt(array[i]));
                        }
                        updateModel["RoleId"] = array1;
                    }
                }

                else if (item === "CompanyId") {
                    if (selectedrow[item] != null) {
                        let array = selectedrow[item].split(",");
                        let array1 = []
                        for (var i = 0; i < array.length; i++) {

                            array1.push(parseInt(array[i]));
                        }
                        updateModel["CompanyId"] = array1;
                    }            
                }
                              
               
                else {
                    updateModel[item] = selectedrow[item]
                }


            }
        }

        this.setState({ dataModel: updateModel, UserId: UserId });

    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'UserName', header: 'UserName', width: '100%' }];
        search.query = "7";
        search.heading = "Find User";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    saveContentData = () => {
        var data = new FormData();
        let userData = this.state;
        let model = userData.dataModel
        data.append('UserId', userData.UserId)
        data.append('UserName', model.UserName)
        data.append('Password', model.Password)
        data.append('CompanyId', model.CompanyId)
        data.append('RoleId', model.RoleId)
        data.append('CompanyId', model.CompanyId)
        this.setState({ loading: true })
        //this.requestSave({ 'UserId': userData.UserId, 'UserName': model.UserName, 'Password': model.Password, 'CompanyId': model.CompanyId, 'RoleId': model.RoleId.toString(), 'CompanyId': model.CompanyId.toString() });
        this.requestSave(data);
        return;

    }
    requestSave = async (data) => {
        let url = APIUrl+'User/InsertUpdateUser'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
                     
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
                this.clearData();
           
        }
        else if (response.status == 201) {

            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Role Name exist.Choose another", messageVisible: true, loading: false });

        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    isValidData = () => {
        let message = '';
        let data = this.state.dataModel

        if (data.UserName == undefined || data.UserName === '') {
            message = '* User Name not be blank!!'
        }

        if (data.Password == undefined || data.Password === '') {
            message += '\n* Password Name not be blank!!'
        }

        if (data.ConfirmPassword !== data.Password ) {
            message += '\n* Password Mismatch!!'
        }


        if (data.RoleId == 'Select'||data.RoleId == undefined || data.RoleId === '') {
            message += '\n* Select atleast one role!!'
        }

        if (data.CompanyId == 'Select'||data.CompanyId == undefined || data.CompanyId === '') {
            message += '\n* Select any company!!'
        }

        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveContentData();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
        var data = new FormData();
        let userData = this.state;
        data.append('UserId', userData.UserId)
        this.requestDelete(data);
    }
    confirmDelete = () => {
        let companyData = this.state;
        if (companyData.CompanyId === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Company found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    requestDelete = async (data) => {
        let url = APIUrl +'User/DeleteUser'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            const jsonData = await response.json();
          
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
                this.clearData();
          
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }


    handleLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["Logo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }


    handleCSLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["CSLogo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }


    render() {
       
        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Clear" icon="pi pi-times" onClick={this.clearData} />
                <Button type="button" label="Close" onClick={this.props.onHide} />
                <Button type="button" label="Find" icon="pi pi-search" onClick={this.showFind} />
                <Button type="button" label="Delete" icon="pi pi-times" onClick={this.confirmDelete} />
            </div>
        );
        return (

            <Dialog footer={footer} header="User" visible={this.props.visible} style={{ width: '50vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.searchObject.visible ? <Search search={this.state.searchObject} /> : ''}
                <div style={{ minHeight: 300 }}>
                    {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                    {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}

                    <div className="p-grid">
                        <div className="p-col-3">User Name:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['UserName']} maxLength={99} onChange={this.handleChange} name="UserName" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Password:</div>
                        <div className="p-col"><Password value={this.state.dataModel['Password']} onChange={this.handleChange} name="Password" style={{ width: "100%" }} />
                           </div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Confirm Password:</div>
                        <div className="p-col"><Password value={this.state.dataModel['ConfirmPassword']} onChange={this.handleChange} name="ConfirmPassword" style={{ width: "100%" }} />
                        </div>

                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">Role:</div>
                        <div className="p-col"><MultiSelect optionValue="value" optionLabel="label" name="RoleId" value={this.state.dataModel["RoleId"]} options={this.state.RoleList} onChange={this.handleChange} placeholder="Select a Role" style={{ width: "100%" }} /></div>
                        
                    </div>             
                    <div className="p-grid">
                        <div className="p-col-3">Company:</div>
                        <div className="p-col"><MultiSelect optionValue="value" optionLabel="label" name="CompanyId" value={this.state.dataModel["CompanyId"]} options={this.state.CompanyList} onChange={this.handleChange} placeholder="Select a Company" style={{ width: "100%" }} /></div>
                        
                    </div>

                </div>
            </Dialog>
        )

    };
}

export default user