import React from 'react';
import { Helmet } from "react-helmet";
import Carousel from 'react-bootstrap/Carousel'
import "./assets/img/favicon.png";
import "./assets/img/apple-touch-icon.png";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/icofont/icofont.min.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/venobox/venobox.css";
import "./assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "./assets/vendor/aos/aos.css";
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/style.css"
import "./StyleSheet.css"
import Header from './Header'
import ServiceSection from './ServiceSection'
import Slider from './Slider'
import Footer from './Footer'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import jquery from 'jquery'
import slide1 from "./assets/img/slide/slide-1.jpg"
import slide2 from "./assets/img/slide/slide-2.jpg"
import slide3 from "./assets/img/slide/slide-3.jpg"
class index extends React.Component {


    componentDidMount() {
                
        AOS.init({
            duration: 2000
        });
    }

    loadScript = (path) => {
        let script = document.createElement("script");
        script.type = "text/jsx"
        script.src = path;
        script.async = true;
        document.body.appendChild(script);
    }
    
    loadHeader = () => {
     return   <Helmet>
            <title>Company Secretary,Company Registration Kerala,Company Registration Kakkanad,Company Registration Infopark,Company Formation Kerala,LLP Registration Kerala,Digital Signature,Business Consultant, Cochin, Trivandrum, Kerala, India,IE Code,TDS,Tax Consultant,Accounts and Taxation</title>
            <meta http-equiv="content-type" content="text/html; charset=windows-1252" />
            <meta name="description" content="Company Secretary Company Registration in Kerala, Company Registration Kerala, Company Registration in India, LLP Registration, LLP Registration India, LLP Registration in kerala, Digital Signature,GST registration,TalkUs provides Company Registration service and LLP Registration service in kerala." />
            <meta name="author" content="TalkUs Corporate Solutions" />
            <meta name="keywords" content="Company Secretery,Company Registration,Online Company Registration,Company Secretary in Angamaly,New Company Formation in Kerala,Company Registration Kerala,New Company formation in Infopark cochin,New Company Registration in aluva,,llp registration in kerala,new company registration,formation of company,formation of a company,Company Registration Kerala, Company Registration in Kerala, LLP Registration Kerala, LLP Registration in Kerala, New Company Registration Kerala, Register company in Kerala, How to register company in kerala, company regsitration process kerala, Business Consultants Trivandrum, Business Consultants India, Business Consultants Kerala, trade marks kerala, e filing kerala, e filing india, digital signatures kerala, digital signature ernakulam, digital signature india, company registration india, company registration kerala,gst registration,gst registration in angamaly" />
            <meta name="classification" content="Business" />
           
            <meta property="og:title" content="Company Secretary,Company Registration Kerala,LLP Registration Kerala,Digital Signature,IE Code,TDS,Tax Consultant,Accounts and Taxation" />
            <meta property="og:type" content="article" />
            <meta property="og:image" content="/static/media/slide-2.jpg" />
          
            <meta itemprop="description" content="Welcome to Talkus Corporate Solutions. We provide specialized services to the entrepreneurs to establish their projected business in India as per the Companies Act and other Legal Compliances" />
            <meta name="google-site-verification" content="chzDKllaqZQ__PntR2OF4mkGik8b38C0ty8yGgzSyUk" />
            <meta name="google-site-verification" content="MmkSpYGVVLOE8MjwxS8Juue3R2kfs6IWqFxAhNf-GbE" />
        </Helmet>
    }

    render() {

        return (

            <div>
                <Header menuName={"Home"} param={this.props}/ >
                <Slider />
                <main id="main">

                    <section id="about-us" className="about-us">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2><strong>About Us</strong></h2>
                            </div>

                            <div className="row content">
                                <div className="col-lg-6" data-aos="fade-right">
                                  
                                    <h3> Welcome to Talkus Corporate Solutions. We provide specialized services to the entrepreneurs to establish their projected business in India as per the Companies Act and other Legal Compliances</h3>
                                </div>
                                <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
                                    <p>
                                      We offer a wide range of services including Company formation, LLP Registration, Trademarks Registration, Digital Signature services, Compliance under Company Law and other Statutes, Corporate Advisory Services, etc. We hope you enjoy our services as much as we enjoy offering them to you. If you have any questions or comments, please don’t hesitate to contact us.
            </p>
                                    
                                </div>
                            </div>

                        </div>
                    </section>
                    <ServiceSection />
                                                        
                    <section>
                        <section id="contact" className="contact">
                            <div className="container">
                                <div className="section-title">
                                    <h2><strong>Contact Us</strong></h2>
                                </div>
                                <div className="row justify-content-center" data-aos="fade-up">

                                    <div className="col-lg-10">

                                        <div className="info-wrap">
                                            <div className="row">
                                                <div class="col-lg-4 info">
                                                    <i className="icofont-google-map"></i>
                                                    <h4>Location:</h4>
                                                    <p>TalkUs Corporate Solutions{"\n"}First floor,St George Complex,Bank junction {"\n"}Angamaly-683572</p>
                                                </div>

                                                <div className="col-lg-4 info mt-4 mt-lg-0">
                                                    <i className="icofont-envelope"></i>
                                                    <h4>Email:</h4>
                                                    <p>talkuscs@gmail.com{"\n"}talkusinfo@gmail.com</p>
                                                </div>

                                                <div className="col-lg-4 info mt-4 mt-lg-0">
                                                    <i className="icofont-phone"></i>
                                                    <h4>Call:</h4>
                                                    <p>+919846505914, 0484-2458331</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                               

                            </div>
                        </section>
                        <div className="map-section">
                            <iframe style={{ border: 0, width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.8928885077567!2d76.38332231428316!3d10.18935327254223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0806676ac10109%3A0xb558ae4bed50fa7c!2sTalkus%20Corporate%20Solutions!5e0!3m2!1sen!2sin!4v1591431316876!5m2!1sen!2sin" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </section>
                </main>
                <Footer/>
                <Link to="#" className="back-to-top"><i className="icofont-simple-up"></i></Link>

            </div>

        );
    }


}
export default index;