import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Message from '../Common/Message'
import { FileUpload } from 'primereact/fileupload';
import './flexGrid.css';
import Loading from '../Loading';
import Moment from 'moment';
import Search from '../Common/Search'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { APIUrl } from "../Common/Api";
class Company extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            Bank1Model: [],
            Bank2Model: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '' },
            CompanyId: '',
            activeIndex: '',
            activeIndex1: '',
            accountList: [],


        }
    }
    componentWillMount() {
        this.clearData();
        this.fillAccountType();
    }

    fillAccountType = () => {

        let list = [];

        list.push({ 'label': 'Savings Account', 'value': 'Savings Account' })
        list.push({ 'label': 'Current Account', 'value': 'Current Account' })
        this.setState({ accountList:list})


    }

    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }
    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }

    handleBank1Change = (e) => {
        let updateModel = this.state.Bank1Model
        updateModel[e.target.name] = e.target.value
        this.setState({ Bank1Model: updateModel });
       
    }

    handleBank2Change = (e) => {
        let updateModel = this.state.Bank2Model
        updateModel[e.target.name] = e.target.value
        this.setState({ Bank2Model: updateModel });
       }


    clearLogo = (e) => {
        let updateModel = this.state.dataModel
        updateModel["Logo"] = ""
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }
    clearSignature = (e) => {
        let updateModel = this.state.dataModel
        updateModel["CSLogo"] = ""
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }
    clearData = () => {
        let model = this.state.dataModel
        model['Name'] = '';
        model['Description'] = ''
        model['Email'] = ''
        model['Phone'] = ''
        model['Logo'] = ''
        model['CSLogo'] = ''
        model['BillFormat'] = ''
        model['BillNo'] = ''
        model['Pan'] = ''
        this.clearBank1Data();
        this.clearBank2Data();
        this.setState({ dataModel: model, CompanyId: '' })

    }
    clearBank1Data = () => {
        let model = this.state.Bank1Model
        model['Heading'] = '';
        model['AccountNo'] = ''
        model['PersonName'] = ''
        model['Bank'] = ''
        model['Branch'] = ''
        model['IFSCCode'] = ''
        model['AccountType'] = ''
        this.setState({ Bank1Model: model })

    }
    clearBank2Data = () => {
        let model = this.state.Bank2Model
        model['Heading'] = '';
        model['AccountNo'] = ''
        model['PersonName'] = ''
        model['Bank'] = ''
        model['Branch'] = ''
        model['IFSCCode'] = ''
        model['AccountType'] = ''
        this.setState({ Bank2Model: model })

    }

    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: false })
        let updateModel = this.state.dataModel
        let CompanyId = 0;
        for (var item in selectedrow) {
            if (selectedrow.hasOwnProperty(item)) {

                if (item === "CompanyId") {
                    CompanyId = selectedrow[item];
                }
                else if (item === "CompanyName") {
                    updateModel["Name"] = selectedrow[item]
                }
                else if (item === "Description") {
                    updateModel["Description"] = selectedrow[item]
                }

                else if (item === "phone") {
                    updateModel["Phone"] = selectedrow[item]
                }
                else if (item === "email") {
                    updateModel["Email"] = selectedrow[item]
                }


                else if (item === "image") {
                    updateModel["Logo"] = selectedrow[item]
                }

                else if (item === "image1") {
                    updateModel["CSLogo"] = selectedrow[item]
                }

                else if (item === "billformat") {
                    updateModel["BillFormat"] = selectedrow[item]
                }
                else if (item === "billno") {
                    updateModel["BillNo"] = selectedrow[item]
                }


                else {
                    updateModel[item] = selectedrow[item]
                }


            }
        }

        this.setState({ dataModel: updateModel, CompanyId: CompanyId });

        this.getBankDetails(CompanyId);

    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'CompanyName', header: 'Company Name', width: '30%' }, { field: 'Description', header: 'Description', width: '70%' }];
        search.query = "5";
        search.heading = "Find Company";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }
    saveCompanyData = () => {
        var data = new FormData();
        let companyData = this.state;
        let model = companyData.dataModel


        //let arrayBank = []

        //let bank1 = this.state.Bank1Model
        //if (bank1["Heading"] != "") {

           
        //    arrayBank.push({
        //        'Heading': bank1.Heading, 'AccountNo': bank1.AccountNo,
        //        'PersonName': bank1.PersonName, 'Bank': bank1.Bank, 'Branch': bank1.Branch,
        //        'IFSCCode': bank1.IFSCCode, 'AccountType': bank1.AccountType
        //    })

        //     bank1 = this.state.Bank2Model
        //    if (bank1["Heading"] != "") {
        //        arrayBank.push({
        //            'Heading': bank1.Heading, 'AccountNo': bank1.AccountNo,
        //            'PersonName': bank1.PersonName, 'Bank': bank1.Bank, 'Branch': bank1.Branch,
        //            'IFSCCode': bank1.IFSCCode, 'AccountType': bank1.AccountType
        //        })

        //    }
           
        //}

        //let data = {
        //    'CompanyId': companyData.CompanyId,
        //    'Name': model.Name,
        //    'Name': model.Name,
        //    'Description': model.Description,
        //    'Email': model.Email,
        //    'Phone': model.Phone,
        //    'Logo': model.Logo,
        //    'CSLogo': model.CSLogo,
        //    'BillFormat': model.BillFormat,
        //    'BillNo': model.BillNo,
        //    'Pan': model.Pan,
        //    BankDetails: arrayBank
        //}

        data.append('CompanyId', companyData.CompanyId)
        data.append('Name', model.Name)
        data.append('Description', model.Description)
        data.append('Email', model.Email)
        data.append('Phone', model.Phone)
        data.append('Logo', model.Logo)
        data.append('CSLogo', model.CSLogo)
        data.append('BillFormat', model.BillFormat)
        data.append('BillNo', model.BillNo)
        data.append('Pan', model.Pan)
        let bank1 = this.state.Bank1Model
        if (bank1["Heading"] != "") {
            data.append('BankDetails[0].Heading', bank1.Heading)
            data.append('BankDetails[0].AccountNo', bank1.AccountNo)
            data.append('BankDetails[0].PersonName', bank1.PersonName)
            data.append('BankDetails[0].Bank', bank1.Bank)
            data.append('BankDetails[0].Branch', bank1.Branch)
            data.append('BankDetails[0].IFSCCode', bank1.IFSCCode)
            data.append('BankDetails[0].AccountType', bank1.AccountType)
        }

        let bank2 = this.state.Bank2Model
        if (bank2["Heading"] != "") {
            data.append('BankDetails[1].Heading', bank2.Heading)
            data.append('BankDetails[1].AccountNo', bank2.AccountNo)
            data.append('BankDetails[1].PersonName', bank2.PersonName)
            data.append('BankDetails[1].Bank', bank2.Bank)
            data.append('BankDetails[1].Branch', bank2.Branch)
            data.append('BankDetails[1].IFSCCode', bank2.IFSCCode)
            data.append('BankDetails[1].AccountType', bank2.AccountType)
        }
        
        this.setState({ loading: true })
        this.requestSave(data);
        return;

    }
    requestSave = async (data) => {
        let url = APIUrl+'Company/InsertUpdateCompany'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
                this.clearData();
            
        }
        else if (response.status == 306) {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Bill Format exist.Choose another", messageVisible: true, loading: false });
        }
        else if (response.status == 201) {

            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Company Name exist.Choose another", messageVisible: true, loading: false });

        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    isValidData = () => {
        let message = '';
        let data = this.state.dataModel

        if (data.Name == undefined || data.Subject === '') {
            message = '* Name No not be blank!!'
        }
        if (data.Description == undefined || data.Description === '') {
            message += '\n* Description not be blank!!'
        }

        if (data.BillFormat == undefined || data.BillFormat === '') {
            message += '\n* BillFormat not be blank!!'
        }
        if (data.BillNo == undefined || data.BillNo === '') {
            message += '\n* BillNo not be blank!!'
        }

        let bankData = this.state.Bank1Model

        if (bankData.Heading !== undefined && bankData.Heading !== '') {

            if (bankData.AccountNo == undefined || bankData.AccountNo === '') {
                message += '\n* Bank1 AccountNo not be blank!!'
            }

            if (bankData.PersonName == undefined || bankData.PersonName === '') {
                message += '\n* Bank1 Person Name not be blank!!'
            }

            if (bankData.Bank == undefined || bankData.Bank === '') {
                message += '\n* Bank1 Name not be blank!!'
            }

            if (bankData.Branch == undefined || bankData.Branch === '') {
                message += '\n* Bank1 Branch not be blank!!'
            }

            if (bankData.IFSCCode == undefined || bankData.IFSCCode === '') {
                message += '\n* Bank1 IFSC Code not be blank!!'
            }
            if (bankData.AccountType == undefined || bankData.AccountType === '') {
                message += '\n* Select Bank1 accountType!!'
            }
        }

        let bank2Data = this.state.Bank2Model

        if (bank2Data.Heading !== undefined && bank2Data.Heading !== '') {

            if (bank2Data.AccountNo == undefined || bank2Data.AccountNo === '') {
                message += '\n* Bank2 AccountNo not be blank!!'
            }

            if (bank2Data.PersonName == undefined || bank2Data.PersonName === '') {
                message += '\n* Bank2 Person Name not be blank!!'
            }

            if (bank2Data.Bank == undefined || bank2Data.Bank === '') {
                message += '\n* Bank2 Name not be blank!!'
            }

            if (bank2Data.Branch == undefined || bank2Data.Branch === '') {
                message += '\n* Bank2 Branch not be blank!!'
            }

            if (bank2Data.IFSCCode == undefined || bank2Data.IFSCCode === '') {
                message += '\n* Bank2 IFSC Code not be blank!!'
            }
            if (bank2Data.AccountType == undefined || bank2Data.AccountType === '') {
                message += '\n* Select Bank2 accountType!!'
            }
        }


        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveCompanyData();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
        var data = new FormData();
        let companyData = this.state;
        data.append('CompanyId', companyData.CompanyId)
        this.requestDelete(companyData.CompanyId);
    }
    confirmDelete = () => {
        let companyData = this.state;
        if (companyData.CompanyId === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Company found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    requestDelete = async (data) => {
        let url = APIUrl+ 'Company/DeleteCompany'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
                this.clearData();
            
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }
    getBankDetails = async (CompanyId) => {
        let url = APIUrl+'Search/GetBankDetails'
        let data = new FormData
        data.append("CompanyId", CompanyId)
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            //jsonData = JSON.parse(jsonData);
            if (jsonData.length > 0) {
                let model = this.state.Bank1Model
                model['Heading'] = jsonData[0].Heading;
                model['AccountNo'] = jsonData[0].AccountNo
                model['PersonName'] = jsonData[0].Name
                model['Bank'] = jsonData[0].Bank
                model['Branch'] = jsonData[0].Branch
                model['IFSCCode'] = jsonData[0].IFSCCode
                model['AccountType'] = jsonData[0].AccountType
                this.setState({ Bank1Model: model });
            }

            if (jsonData.length > 1) {
                let model = this.state.Bank2Model
                model['Heading'] = jsonData[1].Heading;
                model['AccountNo'] = jsonData[1].AccountNo
                model['PersonName'] = jsonData[1].Name
                model['Bank'] = jsonData[1].Bank
                model['Branch'] = jsonData[1].Branch
                model['IFSCCode'] = jsonData[1].IFSCCode
                model['AccountType'] = jsonData[1].AccountType
                this.setState({ Bank2Model: model });
            }
            
        }
        else {
            this.setState({ loading: false })

        }

    }

    handleLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["Logo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }


    handleCSLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["CSLogo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }


    render() {


        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Clear" icon="pi pi-times" onClick={this.clearData} />
                <Button type="button" label="Close" onClick={this.props.onHide} />
                <Button type="button" label="Find" icon="pi pi-search" onClick={this.showFind} />
                <Button type="button" label="Delete" icon="pi pi-times" onClick={this.confirmDelete} />
            </div>
        );
        return (

            <Dialog footer={footer} header="Company" visible={this.props.visible} style={{ width: '50vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.searchObject.visible ? <Search search={this.state.searchObject} /> : ''}
                <div style={{ minHeight: 300 }}>
                    {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                    {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}

                    <div className="p-grid">
                        <div className="p-col-3">*Company Name:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['Name']} maxLength={99} onChange={this.handleChange} name="Name" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Description:</div>
                        <div className="p-col"><InputTextarea type="text" value={this.state.dataModel['Description']} maxLength={99} onChange={this.handleChange} name="Description" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Bill Format:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['BillFormat']} maxLength={99} onChange={this.handleChange} name="BillFormat" style={{ width: "100%" }} /></div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Bill No:</div>
                        <div className="p-col"><InputText keyfilter="pint" type="text" value={this.state.dataModel['BillNo']} maxLength={99} onChange={this.handleChange} name="BillNo" style={{ width: "100%" }} /></div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Email:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['Email']} maxLength={99} onChange={this.handleChange} name="Email" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Phone:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['Phone']} maxLength={99} onChange={this.handleChange} name="Phone" style={{ width: "100%" }} /></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">PAN:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['Pan']} maxLength={99} onChange={this.handleChange} name="Pan" style={{ width: "100%" }} /></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">Company header:</div>
                        <div className="p-col"><FileUpload name="demo" onSelect={this.handleLogo} mode="basic"></FileUpload></div>
                        <div className="p-col-4"><img src={this.state.dataModel["Logo"]} style={{ maxHeight: 100, maxWidth: 100 }} />{this.state.dataModel["Logo"] != "" ? <i className="pi pi-times" onClick={this.clearLogo}></i> : ""}</div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">Company Signature:</div>
                        <div className="p-col"><FileUpload name="demo" mode="basic" onSelect={this.handleCSLogo}></FileUpload></div>
                        <div className="p-col-4"><img src={this.state.dataModel["CSLogo"]} style={{ maxHeight: 100, maxWidth: 100 }} />{this.state.dataModel["CSLogo"] != "" ? <i className="pi pi-times" onClick={this.clearSignature}></i> : ""}</div>
                    </div>
                    <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                        <AccordionTab header="First Bank details">
                            <div className="p-grid">
                                <div className="p-col-3">Heading:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['Heading']} maxLength={99} onChange={this.handleBank1Change} name="Heading" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Account Number:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['AccountNo']} maxLength={99} onChange={this.handleBank1Change} name="AccountNo" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Name:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['PersonName']} maxLength={99} onChange={this.handleBank1Change} name="PersonName" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Bank:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['Bank']} maxLength={99} onChange={this.handleBank1Change} name="Bank" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Branch:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['Branch']} maxLength={99} onChange={this.handleBank1Change} name="Branch" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Account Type:</div>
                                <div className="p-col"><Dropdown optionLabel="label" name="AccountType" value={this.state.Bank1Model["AccountType"]} options={this.state.accountList} onChange={this.handleBank1Change} placeholder="Select account Type" style={{ width: "100%" }} /></div>

                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">IFSC Code:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank1Model['IFSCCode']} maxLength={99} onChange={this.handleBank1Change} name="IFSCCode" style={{ width: "100%" }} /></div>
                            </div>

                         </AccordionTab>

                    </Accordion>
                    <Accordion activeIndex={this.state.activeIndex1} onTabChange={(e) => this.setState({ activeIndex1: e.index })}>
                        <AccordionTab header="Second Bank details">
                            <div className="p-grid">
                                <div className="p-col-3">Heading:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['Heading']} maxLength={99} onChange={this.handleBank2Change} name="Heading" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Account Number:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['AccountNo']} maxLength={99} onChange={this.handleBank2Change} name="AccountNo" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Name:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['PersonName']} maxLength={99} onChange={this.handleBank2Change} name="PersonName" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Bank:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['Bank']} maxLength={99} onChange={this.handleBank2Change} name="Bank" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Branch:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['Branch']} maxLength={99} onChange={this.handleBank2Change} name="Branch" style={{ width: "100%" }} /></div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">Account Type:</div>
                                <div className="p-col"><Dropdown optionLabel="label" name="AccountType" value={this.state.Bank2Model["AccountType"]} options={this.state.accountList} onChange={this.handleBank2Change} placeholder="Select account Type" style={{ width: "100%" }} /></div>

                            </div>
                            <div className="p-grid">
                                <div className="p-col-3">IFSC Code:</div>
                                <div className="p-col"><InputText type="text" value={this.state.Bank2Model['IFSCCode']} maxLength={99} onChange={this.handleBank2Change} name="IFSCCode" style={{ width: "100%" }} /></div>
                            </div>

                        </AccordionTab>

                    </Accordion>
                </div>
            </Dialog>
        )

    };
}

export default Company