import React from 'react'
import { Link } from 'react-router-dom';
import { APIUrl } from '../Common/Api'
class footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }

    }

    componentDidMount() {
        this.getServices();
    }

    getServices = async () => {
        //let url = '/Search/GetOurServices'

        let url = APIUrl+"Search/GetOurservices"
        let response = await fetch(url)
        if (response.status == 200) {
            let jsonData = await response.json();
            this.setState({ data: jsonData })
           // this.setState({ data: JSON.parse(jsonData)})
                                 
        }
       
    }

   
    render() {

       
        const listServices = this.state.data.map((data) => {
            if (data.category === 1) {
                return < li > <i className="bx bx-chevron-right"></i> <Link to={`/Services/${data.Subject}`}>{data.Subject}</Link></li >
            } else {

                return "";
            }
        });
        const listInformation = this.state.data.map((data) => {
            if (data.category === 2) {
                return < li > <i className="bx bx-chevron-right"></i> <Link to={`/Services/${data.Subject}`}>{data.Subject}</Link></li >
            } else {

                return "";
            }
        });

        const listNews = this.state.data.map((data) => {
            if (data.category === 3) {
                return < li > <i className="bx bx-chevron-right"></i> <Link to={`/Services/${data.Subject}`}>{data.Subject}</Link></li >
            } else {

                return "";
            }
        });

        const listQuery = this.state.data.map((data) => {
            if (data.category === 4) {
                return < li > <i className="bx bx-chevron-right"></i> <Link to={`/Services/${data.Subject}`}>{data.Subject}</Link></li >
            } else {

                return "";
            }
        });
        return (<div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3>Contact Us</h3>
                                <h4>Angamaly</h4>
                                <p>TalkUs Corporate Solutions<br></br>
                                    First floor,St George Complex,<br></br>
                                    Bank Junction,Angamaly-683572<br></br>
                                  
                                   </p>
                                <br></br>
                                <h4>Aluva</h4>
                                <p>TalkUs Corporate Solutions<br></br>
                                    First floor, patuvana building,<br></br>
                                    Market Road,Market junction Aluva<br></br>
                                    </p>
                                <br></br>
                                <h4>Ernakulam</h4>
                                <p>DV & Associates<br></br>
                                Second Floor,Tharayath,Lane14<br></br>
                                    Janatha Road, Vytila,<br></br>
                                    Ernakulam ,Kerala<br></br>
                                </p>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="#">Home</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="#">About us</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="#">Services</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="#">Terms of service</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="#">Privacy policy</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>{listServices}
                            </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Informations</h4>
                                <ul>{listInformation}
                                </ul>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-md-6 footer-links">
                                <h4>News</h4>
                                <ul>{listNews}
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-6 footer-links">
                                <h4>Query Posts</h4>
                                <ul>{listQuery}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container d-md-flex py-4">

                    <div className="mr-md-auto text-center text-md-left">
                        <div className="copyright">
                            &copy; Copyright <strong><span>TalkUs</span></strong>. All Rights Reserved
        </div>

                    </div>
                    <div className="social-links text-center text-md-right pt-3 pt-md-0">
                        <Link to="https://twitter.com/intent/tweet" className="twitter"><i className="bx bxl-twitter"></i></Link>
                        <Link to="#" className="facebook"><i className="bx bxl-facebook"></i></Link>
                        <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                        <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link>
                        <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                    </div>
                </div>
            </footer>
        </div>)

    }


}
export default footer;