import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../AdminLayout/Style.css';
class message extends React.Component {

       
    
    render() {
        const btnStyle = (this.props.status == true ? "p-button-raised p-button-success" : "p-button-raised p-button-warning" )
        const messageStyle = (this.props.status == true ? "successMessage" : "errorMessage")
        
        const footer = (
            <div>
                {this.props.confirm ? <div><Button type="button" label="Ok" className="p-button-raised p-button-success" onClick={this.props.continue} />
                    <Button type="button" label="Cancel" className="p-button-raised p-button-success" onClick={this.props.onHide} /></div>:
                    <Button type="button" label="Ok" className={btnStyle} onClick={this.props.onHide} />}
                </div>
        );
        return (
            <Dialog footer={footer} header={this.props.header} visible={this.props.visible} style={{ width: '30vw'}} modal={true} onHide={this.props.onHide}>

                <div className={messageStyle}>{this.props.message}</div>
                
                             
            </Dialog>
                )
            
    };
}

export default message