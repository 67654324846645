import { APIUrl } from '../components/Common/Api'
const requestLoginType = 'REQUEST_LOGIN';
const receiveLoginType = 'RECEIVE_LOGIN';
const initialState = { showAdmin: false, isLogin: false, user: [], message: '', messageVisible: false, loading: false };
export const actionCreators = {
    requestLogin: data => async (dispatch, getState) => {

        dispatch({ type: requestLoginType });

        let url = APIUrl + "Login/UserLogin"
       // let url = '/Login/UserLogin'

        const requestOptions = {
            method: 'POST',
            //body: JSON.stringify(data)
            //method: 'POST',
            body: data,

        };
        let response = await fetch(url, requestOptions)

        //const result = await response.json();
        if (response.status == 200) {

            const jsonData = await response.json();
            let result = jsonData

            dispatch({ type: receiveLoginType, response, result });
        }
        else {
            dispatch({ type: receiveLoginType, response });

        }

        //dispatch({ type: receiveLoginType, response, result });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestLoginType) {
        return {
            ...state,
            message: '',
            loading: true
        };
    }

    if (action.type === receiveLoginType) {
        if (action.response.status == 200) {
            return {
                ...state,
                user: action.result,
                loading: false,
                isLogin: action.result.isLogin,
                showAdmin: action.result.isLogin
            };
        } else {
            return {
                ...state,
                loading: false,
                isLogin: false,
                showAdmin: false,
                message:"Invalid username or password"
            };

        }
    }

    return state;
};
