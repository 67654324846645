import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, withRouter
} from "react-router-dom";
import {  browserHistory } from 'react-router';
import AdminHome from './components/AdminLayout/AdminHome';
import Index from './components/Templates/Index';
import Contact from './components/Templates/Contact';
import About from './components/Templates/AboutUs';
import Services from './components/Templates/Services';
import Blog from './components/Templates/Blog';
import FormBuilder from './components/Form/DragThingsToBoxesDemo';
export default () => (
     
    <Router history={browserHistory}>
        <div>
    
            <Route exact path='/' component={Index} />
            <Route path="/Contact" component={Contact} />
            <Route path="/About" component={About} />
            <Route path="/Admin" component={AdminHome} />
            <Route path="/Blog/:id" component={Blog} />
        <Route path="/Services/:id" component={Services} />
       
            </div>
    </Router>
    
);
