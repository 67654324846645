import React from 'react';
import "./assets/img/favicon.png";
import "./assets/img/apple-touch-icon.png";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/icofont/icofont.min.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/venobox/venobox.css";
import "./assets/vendor/owl.carousel/assets/owl.carousel.min.css";
import "./assets/vendor/aos/aos.css";
import { APIUrl } from '../Common/Api'
import "./assets/vendor/remixicon/remixicon.css";
import "./assets/css/style.css"
import "./StyleSheet.css"
import AOS from 'aos';
import Header from './Header'
import Footer from './Footer'
import ServiceSection from './ServiceSection'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
class index extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            content: "",
            heading:""

        }


    }
    componentDidMount() {
        this.getContent(this.props.match.params.id)
        AOS.init({
          duration: 2000
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id != prevProps.match.params.id) {
            this.getContent(this.props.match.params.id)
        }
    }
    getContent = async () => {

        
       // let url = API+"GetContent/" + this.props.match.params.id
       // let url = APIUrl +"api/GetContent/" + this.props.match.params.id
        let url = APIUrl+'Content/GetContent/' + this.props.match.params.id
               
        let response = await fetch(url);
        if (response.status == 200) {
            const jsonData = await response.json();
            if (jsonData != "") {
                let data=jsonData
                this.setState({ content: data[0].PostContent, heading: data[0].Subject })
            }
                      
        }

    }







    render() {

        return (
            <div>

                    <Helmet>
    <title>{this.state.heading}</title>
    <meta http-equiv="content-type" content="text/html; charset=windows-1252" />
    <meta name="description" content={this.state.heading} />

</Helmet>



                <Header menuName={"Services"} param={this.props} />
                <main id="main">

                    
    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="container">

                            <div className="d-flex justify-content-between align-items-center">
                                <h2>Services</h2>
                                <ol>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Services</li>
                                </ol>
                            </div>

                        </div>
                    </section>
                
    
    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h1>{this.state.heading}</h1>
                            </div>
                            <div className="row">
                                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                                </div>    
                            

                        </div>
                    </section>
                    <ServiceSection />
                    <section id="contact" className="contact">
                        <div className="container">

                            <div className="row justify-content-center" data-aos="fade-up">

                                <div className="col-lg-10">

                                    <div className="info-wrap">
                                        <div className="row">
                                            <div class="col-lg-4 info">
                                                <i className="icofont-google-map"></i>
                                                <h4>Location:</h4>
                                                <p>TalkUs Corporate Solutions{"\n"}First floor,St George Complex,Bank junction {"\n"}Angamaly-683572</p>
                                            </div>

                                            <div className="col-lg-4 info mt-4 mt-lg-0">
                                                <i className="icofont-envelope"></i>
                                                <h4>Email:</h4>
                                                <p>talkuscs@gmail.com{"\n"}talkusinfo@gmail.com</p>
                                            </div>

                                            <div className="col-lg-4 info mt-4 mt-lg-0">
                                                <i className="icofont-phone"></i>
                                                <h4>Call:</h4>
                                                <p>+919846505914, 0484-2458331</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </section>
                </main>
                <Footer />
                <Link to="#" className="back-to-top"><i className="icofont-simple-up"></i></Link>

            </div>

        );
    }


}
export default index;