import React from 'react'
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import VoucherEntry from './VoucherEntry'
import Receipt from './Receipt'
import Moment from 'moment';
import Loading from '../Loading'
import { MultiSelect } from 'primereact/multiselect';
import { APIUrl } from "../Common/Api";
let ReceiptTotal = 0.00;
let PaymentTotal = 0.00;
let Company = "";

class report extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
            receiptData: [],
            paymentData: [],
            fromDate: this.firstDayMonth(),
            toDate: this.formatdate(new Date()),
            loading: false,
            globalFilter: '',
            globalFilterPayment: '',
            receiptTotal: 0.00,
            voucherVisible: false,
            selectedRow: '',
            receiptVisible: false,
            CompanyList: [],
            CompanyId: '',
          
        }

    }

    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }

    firstDayMonth = () => {

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        firstDay = Moment(firstDay, 'DD/MM/YYYY').toDate();
        return firstDay;

    }



    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }

    componentWillMount() {

          this.clearFilter();

    }
   
    fillCompany = () => {
        let jsonData = this.props.loginUser.companyList
        let list = [];
        let array = [];
        for (var i = 0; i < jsonData.length; i++) {
            list.push({ 'label': jsonData[i].CompanyName, 'value': jsonData[i].CompanyId })
            array.push(jsonData[i].CompanyId )
        }
        this.setState({ CompanyList: list, CompanyId: array})
    }

    getReport = () => {
        
        ReceiptTotal = 0;
        PaymentTotal = 0
        this.setState({ loading: true })
        this.getReceiptDetails(Company);
        this.setState({ loading: true })
        this.getPaymentDetails(Company);

    }

    clearFilter = () => {
        if (this.props.loginUser.companyList.length > 1) {
            this.fillCompany()
        }
        let currentfromdate = this.state.fromDate;
        currentfromdate = this.firstDayMonth()
        let currenttoDate = this.state.toDate;
        currenttoDate = this.formatdate(new Date());
        Company = this.props.loginUser.company
        this.setState({ fromDate: currentfromdate, toDate: currenttoDate, loading: true});
        this.getReport();

    }

    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }

    getReceiptDetails = async (company) => {
      
        let currentState = this.state;

        let url = APIUrl+'Search/GetReportData1'
        let data = new FormData
        data.append("parameter1", this.formatdateforSave(currentState.fromDate))
        data.append("parameter2", this.formatdateforSave(currentState.toDate))
        data.append("Company", company)

        //let data = { "Parameter1": this.formatdateforSave(currentState.fromDate), "Parameter2": this.formatdateforSave(currentState.toDate), "Company": company.toString()}
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            //jsonData = JSON.parse(jsonData);
            ReceiptTotal = 0;
            if (jsonData.length > 0) {
                for (var i = 0; i < jsonData.length; i++) {
                    ReceiptTotal = ReceiptTotal + parseInt(jsonData[i].Amount)
                }
            }
            this.setState({ receiptData: jsonData, loading: false })

        }
        else {
            this.setState({ loading: false })

        }

    }

   


    getPaymentDetails = async (company) => {
       
        let currentState = this.state;
        let url = APIUrl+'Search/GetReportData2'
        let data = new FormData
        data.append("parameter1", this.formatdateforSave(currentState.fromDate))
        data.append("parameter2", this.formatdateforSave(currentState.toDate))
        data.append("Company", company)

        //let data = { "Parameter1": this.formatdateforSave(currentState.fromDate), "Parameter2": this.formatdateforSave(currentState.toDate), "Company": company.toString() }
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
           // jsonData = JSON.parse(jsonData)
            PaymentTotal = 0;
            if (jsonData.length > 0) {
                for (var i = 0; i < jsonData.length; i++) {
                    PaymentTotal = PaymentTotal + parseInt(jsonData[i].Amount)
                }
            }

            this.setState({ paymentData: jsonData, loading: false })

        }
        else {
            this.setState({ loading: false })

        }

    }
    paymentStatus1 = (rowData, column) => {

        let status = rowData.PaymentStatus;
        let fontWeight = status === 2 ? 'bold' : 'normal';

        return <span style={{ fontWeight: fontWeight }}>{rowData.paymentStatus}</span>;

    }
    rowClassName(rowData) {
        let status = rowData.PaymentStatus;
        return { 'Unpaid': (status === 2) };
    }
    rowPaymentClassName(rowData) {
        let status = rowData.PaymentStatus;
        return { 'Unpaid': (status === 2) };
    }
    setReceiptTotal = (event) => {

        let dd = event;

    }
    SelectRow = (rowData) => {
        this.setState({ voucherVisible: true, selectedRow: rowData })
    }

    SelectReceipt = (rowData) => {
        this.setState({ receiptVisible: true, selectedRow: rowData })
    }

    hideWindow = () => {
        this.setState({ voucherVisible: false })
        this.getReport();
    }
    hideReceipt = () => {
        this.setState({ receiptVisible: false })
        this.getReport();
    }
    dateTemplate = (rowData, column) => {
        return <span className="spanLink" onClick={(e)=>this.SelectRow(rowData)}>{rowData.VoucherDate}  </span>;
    }
    voucherTemplate = (rowData, column) => {
        return <span className="spanLink" onClick={(e) => this.SelectReceipt(rowData)}>{rowData.VoucherNo}  </span>;
    }
    handleChange = (e) => {
        Company = e.target.value;
        this.setState({ CompanyId: e.target.value});
    }
    render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <div style={{ width: "100%" }}><i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText style={{ paddingRight: '10' }} autoFocus type="search" onInput={e => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
                </div>
            </div>
        );

        const headerPayment = (
            <div style={{ 'textAlign': 'left' }}>
                <div style={{ width: "100%" }}><i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText style={{ paddingRight: '10' }} autoFocus type="search" onInput={e => this.setState({ globalFilterPayment: e.target.value })} placeholder="Global Search" size="50" />
                </div>
            </div>
        );
        const receiptFooter = (<div>Total:{ReceiptTotal.toFixed(2)}</div>)
        const paymentFooter = (<div>Total:{PaymentTotal.toFixed(2)}</div>)
        return (
            <Dialog header="Report" visible={this.props.visible} style={{ width: '100%', margin: 0, height: "100vh" }} modal={true} onHide={this.props.onHide} >
                {this.state.loading ? <Loading loading={this.state.loading} /> : ''}
                {this.state.voucherVisible ? < VoucherEntry loginUser={this.props.loginUser} selectedRow={this.state.selectedRow} visible={this.state.voucherVisible} onHide={this.hideWindow} /> : ''}
                {this.state.receiptVisible ? < Receipt loginUser={this.props.loginUser} selectedRow={this.state.selectedRow} visible={this.state.receiptVisible} onHide={this.hideReceipt} /> : ''}
                <div className="p-grid">
                    <div className="p-col-2">
                        <Calendar value={this.state.fromDate} onChange={(e) => this.setState({ fromDate: this.formatdate(e.value) })} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange="2013:2030" dateFormat="dd/mm/yy" />
                    </div>
                    <div className="p-col-2">
                        <Calendar value={this.state.toDate} onChange={(e) => this.setState({ toDate: this.formatdate(e.value) })} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange="2013:2030" dateFormat="dd/mm/yy" />
                    </div>
                    <div className="p-col-1" style={{ textAlign: "right" }}>
                        {this.props.loginUser.companyList.length > 1 ? <MultiSelect optionValue="value" optionLabel="label" name="CompanyId" value={this.state.CompanyId} options={this.state.CompanyList} placeholder="Select Company" onChange={this.handleChange} style={{ width: "100%" }} /> : ""}
                    </div>
                    <div className="p-col-1">
                        <Button type="button" icon="pi pi-search" label="Find" style={{ width: 90 }} onClick={this.getReport} />
                    </div>
                    <div className="p-col-1">
                        <Button type="button" icon="pi pi-search" label="Clear " style={{ width: 90 }} onClick={this.clearFilter} />
                    </div>
                    <div className="p-col-1">
                        <Button type="button"  label="Close " style={{ width: 90 }} onClick={this.props.onHide} />
                    </div>
                    <div className="p-col-1" style={{ textAlign: "right" }}>
                        Income:{(ReceiptTotal - PaymentTotal).toFixed(2)}
                    </div>
                    
                </div>


                <div className="p-grid">
                    <div className="p-col">
                        <DataTable footer={paymentFooter} responsive={true} value={this.state.paymentData} editable={true} border={true} responsive={true} scrollable={true} scrollHeight="300px" header={headerPayment} globalFilter={this.state.globalFilterPayment} emptyMessage="No records found" rowClassName={this.rowPaymentClassName}>
                            <Column field="VoucherDate" header="Bill Date" style={{ width: '1.5em' }} body={this.dateTemplate} />
                            <Column field="VoucherNo" header="Bill No" style={{ width: '1.8em' }} />
                            <Column field="Particulars" header="Particulars" style={{ width: '3.5em' }} body={this.particularsTemplate} />
                            <Column field="Amount" header="Amount" style={{ width: '1.5em' }} />

                        </DataTable>
                    </div>
                    <div className="p-col">
                        <DataTable  footer={receiptFooter}  responsive={true} value={this.state.receiptData} editable={true} border={true} responsive={true} scrollable={true} scrollHeight="300px" header={header} globalFilter={this.state.globalFilter} emptyMessage="No records found" rowClassName={this.rowClassName}>
                            <Column field="VoucherDate" header="Invoice Date" style={{ width: '1.5em' }} body={this.dateTemplate} />
                            <Column field="VoucherNo" header="Invoice No" style={{ width: '1.8em' }} body={this.voucherTemplate}  />
                            <Column field="Particulars" header="Particulars" style={{ width: '3.5em' }} />
                            <Column field="Amount" header="Amount" style={{ width: '1.5em' }} />

                        </DataTable>
                    </div>
                </div>


            </Dialog>
        )
    };
}
export default report;