import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Message from '../Common/Message'
import './flexGrid.css';
import Loading from '../Loading';
import Moment from 'moment';
import Search from '../Common/Search'
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
class role extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '' },
            RoleId: '',
            CompanyList: [],
            expandedRow:""

        }
    }
    componentWillMount() {
        this.clearData();
        this.fillCompany();
    }


    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }
    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }
    clearData = () => {
        let model = this.state.dataModel
        model['RoleId'] = '';
        model['RoleName'] = '';
        model['RoleList'] = []
        model['CompanyId'] = ''
        this.setState({ dataModel: model, RoleId: '' })
        

    }
    fillCompany = async () => {


        let url = '/Search/Search'
        let data = new FormData
        data.append("Query",5)
        const requestOptions = {
            method: 'POST',
            body: data

        };
        let response = await fetch(url, requestOptions)

        if (response.status == 200) {
            let jsonData = await response.json();
            let list = [];
            list.push({ 'label': 'Select', 'value': 'Select' })
            for (var i = 0; i < jsonData.length; i++) {

               

                list.push({ 'label': jsonData[i].CompanyName, 'value': jsonData[i].CompanyId })

            }


            this.setState({ CompanyList: list, loading: false })
                      

        }
        else {
            this.setState({ loading: false })

        }

    }
    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: false })
        let updateModel = this.state.dataModel
        let RoleId = 0;
        for (var item in selectedrow) {
            if (selectedrow.hasOwnProperty(item)) {

                if (item === "RoleId") {
                    RoleId = selectedrow[item];
                }

                if (item === "RolesList") {
                                       
                    let array = selectedrow[item].split(",");
                    updateModel["RoleList"] = array;
                }
               
                else {
                    updateModel[item] = selectedrow[item]
                }


            }
        }

        this.setState({ dataModel: updateModel, RoleId: RoleId });

    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'RoleName', header: 'Role Name', width: '100%' }];
        search.query = "6";
        search.heading = "Find Roles";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }
    saveContentData = () => {
        var data = new FormData();
        let roleData = this.state;
        let model = roleData.dataModel
        let roles = '';
        for (var i = 0; i < model.RoleList.length; i++) {

            roles = roles + model.RoleList[i] + ",";
        }
        roles = roles.substring(0, roles.length - 1);

        data.append('RoleId', roleData.RoleId)
        data.append('CompanyId', model.CompanyId)
        data.append('RoleName', model.RoleName)
        data.append('RoleList', roles)
        this.setState({ loading: true })
        this.requestSave(data);
        return;

    }
    requestSave = async (data) => {
        let url = '/Roles/InsertUpdateRoles'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            const jsonData = await response.json();
            if (jsonData === 'Success') {
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
                this.clearData();
            }
            else if (jsonData == 201) {
                this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Role Name exist.Choose another", messageVisible: true, loading: false });
            }

            else {
                this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

            }
        }
        else if (response.status == 201) {

            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Role Name exist.Choose another", messageVisible: true, loading: false });

        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    isValidData = () => {
        let message = '';
        let data = this.state.dataModel

        if (data.RoleName == undefined || data.RoleName === '') {
            message = '* Role Name not be blank!!'
        }
        if (data.RoleList.length === 0) {
            message += '\n* Select atleast one role!!'
        }

        if (data.CompanyId == 'Select'||data.CompanyId == undefined || data.CompanyId === '') {
            message += '\n* Select any company!!'
        }

        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveContentData();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
        var data = new FormData();
        let roleData = this.state;
        data.append('RoleId', roleData.RoleId)
        this.requestDelete(data);
    }
    confirmDelete = () => {
        let companyData = this.state;
        if (companyData.CompanyId === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Company found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    requestDelete = async (data) => {
        let url = '/Roles/DeleteRoles'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            const jsonData = await response.json();
            if (jsonData === 'Success') {
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
                this.clearData();
            }

            else {
                this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

            }
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }


    handleLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["Logo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }


    handleCSLogo = (e) => {

        let reader = new FileReader();
        reader.readAsDataURL(e.files[0]);
        reader.onload = () => {
            let updateModel = this.state.dataModel;
            updateModel["CSLogo"] = reader.result;
            this.setState({ dataModel: updateModel });

        };

    }

    onHandleSelection = (e) => {

        // let updateArray = this.state.menuData;
        let updateArray = Object.assign([], this.state.menuData)
        let masterId = e.target.id.split("_")[0]
        let detailId = e.target.id.split("_")[1]
        let index = 0;
        if (detailId) {
            index = updateArray.findIndex(u => u.menuMasterId == masterId && u.menuDetailId == detailId)

        } else {
            index = updateArray.findIndex(u => u.menuMasterId == masterId)

        }

        updateArray[index][e.target.name] = e.checked;
        this.setState({ menuData: updateArray })

        let test = this.state.menuData;

    }

    handleDrop = (e) => {
        console.log(e)

    }

    //<MultiSelect optionLabel="label" name="RoleList" value={this.state.dataModel["RoleList"]} options={roles} onChange={this.handleChange} placeholder="Select a Role" style={{ width: "100%" }} />

    render() {
        const roles = [

            { label: 'ContentEditor', value: 'ContentEditor' },
            { label: 'Report', value: 'Report' },
            { label: 'Receipt', value: 'Receipt' },
            { label: 'VoucherEntry', value: 'VoucherEntry' },
            { label: 'CSExport', value: 'CSExport' }
        ];

        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Clear" icon="pi pi-times" onClick={this.clearData} />
                <Button type="button" label="Close" onClick={this.props.onHide} />
                <Button type="button" label="Find" icon="pi pi-search" onClick={this.showFind} />
                <Button type="button" label="Delete" icon="pi pi-times" onClick={this.confirmDelete} />
            </div>
        );
        return (

            <Dialog footer={footer} header="Roles" visible={this.props.visible} style={{ width: '100%',marginTop:"0px" }} modal={true} onHide={this.props.onHide}>
                {this.state.searchObject.visible ? <Search search={this.state.searchObject} /> : ''}
                <div style={{ minHeight: 300 }}>
                    {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                    {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}

                    <div className="p-grid">
                        <div className="p-col-3">Role Name:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['RoleName']} maxLength={99} onChange={this.handleChange} name="RoleName" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Roles:</div>
                        <div className="p-col">
                            <MultiSelect optionLabel="label" name="RoleList" value={this.state.dataModel["RoleList"]} options={roles} onChange={this.handleChange} placeholder="Select a Role" style={{ width: "100%" }} />

                         </div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Company:</div>
                        <div className="p-col"><Dropdown optionLabel="label" name="CompanyId" value={this.state.dataModel["CompanyId"]} options={this.state.CompanyList} onChange={this.handleChange} placeholder="Select a Company" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Company:</div>
                        <div className="p-col">

                            <DragDropContainer targetKey="foo" dragData={"tst"} onDrop={this.handleDrop}  >
                                <div>Drag Me!</div>
                            </DragDropContainer>

                            <DropTarget targetKey="foo" dropData={"test data"} >
                                <p>I'm a valid drop target for the object above since we both have the same targetKey!</p>
                            </DropTarget>

                        </div></div>

                </div>
            </Dialog>
        )

    };
}

export default role