import React from 'react'
class footer extends React.Component {

    constructor(props) {
        super(props);

    }

    Showlogin = () => {
        this.setState({ showlogin: true })
        //let response =await fetch("login/login");
        //const json = await response.json();
        //console.log(json.length)
    }

    render() {

        return (<div>
            <div className="jumbotron text-center" style={{ marginBottom: "0" }}>
                <p>Footer</p>
            </div>

        </div>)

    }


}
export default footer;