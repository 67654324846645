import React from "react"
import ReactDOM from 'react-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Loading from '../Loading';
import Customer from './Customer';
import Message from '../Common/Message'
import { APIUrl } from "../Common/Api";
class customerSearch extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            globalFilter: '',
            loading: true,
            selectedRow: '',
            customerVisible: false,
            status: true,
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            messageConfirm: false,
            company: ''
        }

    }

    componentWillMount() {
        this.setState({ company: this.props.Company})
        this.getSearchDetails(this.props.Company)

    }
    
    getSearchDetails = async (company) => {

        if (this.props.visible == true) {
            let url = '';
            url = APIUrl+'Receipt/CustomerDetails'
                       
            var data = new FormData();
            data.append("CompanyId", company);

            const requestOptions = {
                method: 'POST',
                body: data
            };
            let response = await fetch(url, requestOptions)
             //   let response = await fetch(url)

            if (response.status == 200) {
                let jsonData = await response.json();
                if (jsonData != "") {
                    this.setState({ data: jsonData, loading: false })
                }

            }
            else {
                this.setState({ loading: false })
                let message = response.status;
            }
        }
    }

    filterRow = (e) => {
        this.setState({ globalFilter: e.target.value })

    }

    customerHide = () => {
        this.setState({ customerVisible: false })
        this.setState({  loading: true })
        this.getSearchDetails(this.state.company);
    }

    addCustomer = () => {

        this.setState({ customerVisible: true, selectedRow: '', status: true })
    }

    editCustomer = () => {
        if (this.state.selectedRow == '') {
            this.setState({ messageVisible: true, errorMessage: 'Please select atleast one customer for edit.', messageStatus: false, messageHeader: 'Error', messageConfirm: false });
        }
        else {
            this.setState({ customerVisible: true, status: false })
        }
    }

    setBillAddress = () => {

        this.props.setCustomerDetails(this.state.selectedRow);
        this.props.onHide();
    }

    onSelectionChange = (e) => {

        this.setState({ selectedRow: e.value })

    }

    messageHide = () => {

        this.setState({ messageVisible: false, messageConfirm: false })
        this.getSearchDetails(this.state.company);
    }

    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false })
        this.deleteCustomer();
    }
    confirmDelete = () => {
        if (this.state.selectedRow == '') {
            this.setState({ messageVisible: true, errorMessage: 'Please select atleast one customer for delete.', messageStatus: false, messageHeader: 'Error', messageConfirm: false });
        }
        else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the Customer?.', messageStatus: true, messageHeader: 'confirm', messageConfirm: true });
        }
    }

    deleteCustomer = async () => {
        let row = this.state.selectedRow
        var data = new FormData();
        data.append('Id', row.id)
        //var jsonData = JSON.stringify(data)
        let url = APIUrl+'Receipt/DeleteCustomer'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            this.setState({ messageVisible: true, errorMessage: 'Customer deleted Successfully', messageStatus: true, messageHeader: 'Message', messageConfirm: false });
        } else {

            this.setState({ messageVisible: true, errorMessage: 'Customer deletion failed', messageStatus: false, messageHeader: 'Error', messageConfirm: false });
        }

    }



    render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                <InputText autoFocus type="search" onInput={this.filterRow} placeholder="Global Search" size="50" />&nbsp;<Button className="p-button-rounded p-button-secondary" type="button" label="Add" onClick={this.addCustomer} />
                &nbsp;<Button type="button" label="Edit" className="p-button-rounded p-button-secondary" onClick={this.editCustomer} />
                &nbsp;<Button type="button" label="Delete" className="p-button-rounded p-button-secondary" onClick={this.confirmDelete} />

            </div>
        );

        const footer = (
            <div>
                <Button type="button" label="Select" icon="pi pi-check" onClick={this.setBillAddress} />
                <Button type="button" label="Cancel" icon="pi pi-times" onClick={this.props.onHide} />

            </div>
        );
        return (


            <Dialog footer={footer} header="Search" visible={this.props.visible} style={{ width: '80vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                {this.state.customerVisible == true ? <Customer CompanyId={this.props.CompanyId} status={this.state.status} selectedRow={this.state.selectedRow} onHide={this.customerHide} visible={this.state.customerVisible} /> : ''}
                <Loading loading={this.state.loading} />
                <DataTable id="gridSearch" onRowToggle={this.filterRow} onSelectionChange={this.onSelectionChange} selection={this.state.selectedRow} selectionMode="single" scrollable={true} scrollHeight="250px" value={this.state.data} header={header} globalFilter={this.state.globalFilter} emptyMessage="No records found" >
                    <Column field="Name" header="Name" sortable={true} />
                    <Column field="Address" header="Billing Address" sortable={true} />
                    <Column field="Phone" header="Phone" />
                    <Column field="Email" header="Email" />
                    <Column field="id" style={{ width: 0 }}/>
                </DataTable >

            </Dialog>
        )

    };
}

export default customerSearch