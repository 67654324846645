import React from 'react';
import slide1 from "./assets/img/slide/slide-1.jpg"
import slide2 from "./assets/img/slide/slide-2.jpg"
import slide3 from "./assets/img/slide/slide-3.jpg"
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom';
export default function Slider (){
 
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>Company Registration</h2>
                                <p style={{ color: "white" }}>Private & Public Company Registration. Nidthi Company Registration.</p>
                                <div class="text-center"><Link to="/Services/Company%20Registration%20in%20kerala" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide2}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>LLP Registration</h2>
                                <p style={{ color: "white" }}>Registration of limited liability partnership.</p>
                                <div class="text-center"><Link to="/Services/%20Limited%20Liability%20Partnership%20(LLP)" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide3}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>TradeMark Registration</h2>
                                <p style={{ color: "white" }}>We will help you to protect your Brand Name.</p>
                                <div class="text-center"><Link to="/Services/TradeMark Registration" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>GST Filing, Accounting And Taxation Services </h2>
                                <p style={{ color: "white" }}>We are capable of handling GST filing,Accounts,Sales Tax,Service Tax and other Taxation matters.</p>
                                <div class="text-center"><Link to="/Services/GST%20registration%20in%20Ernakulam" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide2}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>Import Export Code (IE-Code)</h2>
                                <p style={{ color: "white" }}>We offer proffessoinal service for obtaining Import Export Code.</p>
                                <div class="text-center"><Link to="/Services/IE%20Code%20Registration" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide3}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>Digital Signature</h2>
                                <p style={{ color: "white" }}>Digital Signature Certificates at reasonable rate.</p>
                                <div class="text-center"><Link to="/Services/Digital%20Signature" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption class="slideCaption">
                        <div class="container">
                            <div class="carousel-content animated fadeInUp">
                                <h2 style={{ color: "white" }}>Websites,Hosting And Software Solutions</h2>
                                <p style={{ color: "white" }}>Providing excellent web solutions.</p>
                                <div class="text-center"><Link to="Services/Software%20Development" className="btn-get-started">Read More</Link></div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            )
    
    

}