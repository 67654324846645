import React from "react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { APIUrl } from '../Common/Api'
import Loading from '../Loading';
class search extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            globalFilter: '',
            loading: true,
            selectedRow: '',
                       
        }

    }

    componentWillMount() {
        this.getSearchDetails()
      
    }
    
    getSearchDetails = async () => {
                  
        let url = APIUrl+'Search/Search'
        let data = new FormData
        let cmpdata = this.props.Company
        data.append("Query", this.props.search.query)
        data.append("CompanyId", cmpdata ? cmpdata : "")

       // let data = { "Query": this.props.search.query, "CompanyId": cmpdata ? cmpdata: ""}
        //let data = { "Query": 1, "CompanyId": "1" }

            const requestOptions = {
                method: 'POST',
                body: data
               
            };
            let response = await fetch(url, requestOptions)
            
            if (response.status == 200) {
                let jsonData = await response.json();
                this.setState({ data: jsonData, loading: false })

                if (this.props.search.filterValue != undefined) {
                    this.setState({ globalFilter: this.props.search.filterValue })
                } 

            }
            else {

                this.setState({ loading: false })
                
            }
        
    }

   

    onSelectionChange = (e) => {

        this.setState({ selectedRow: e.value })

    }

    setColumn = () => {

        let columns = this.props.search.columns.map((column, index) => (
            < Column key={column.field} field={column.field} header={column.header} sortable={true} style={{ width: column.width }} />
                      
                       
                ))
        
        return columns

    }

    

    render() {
        const header = (
            <div style={{ 'textAlign': 'left' }}>
                <div style={{ width: "100%" }}><i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                    <InputText style={{ paddingRight: '10' }} autoFocus value={this.state.globalFilter}  type="search" onInput={e => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
                                    </div>
            </div>
        );

        const footer = (
            <div>
                <Button type="button" label="Select" icon="pi pi-check" onClick={e => { this.props.search.continueFunction(this.state.selectedRow) }} />
                <Button type="button" label="Cancel" icon="pi pi-times" onClick={this.props.search.onHide} />

            </div>
        );
        const Columns = this.setColumn();
        return (


            <Dialog footer={footer} header={this.props.search.heading} visible={this.props.search.visible} style={{ width: '80vw' }} modal={true} onHide={this.props.search.onHide}>
                 <Loading loading={this.state.loading} />
                <DataTable id="gridSearch" onSelectionChange={this.onSelectionChange} selection={this.state.selectedRow} selectionMode="single" scrollable={true} scrollHeight="250px" value={this.state.data} header={header} globalFilter={this.state.globalFilter} emptyMessage="No records found" >
                    {Columns}
                </DataTable >

            </Dialog>
        )

    };
}

export default search