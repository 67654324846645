import React from 'react'
import Login from '../Login/Login'
import logo from '../Images/logo.jpg'
import { Grid, Row, Col, Navbar } from 'react-bootstrap'
import { Nav, Form, Button, NavDropdown, Card } from 'react-bootstrap'
import { FormControl, Carousel, Breadcrumb } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
class header extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showlogin: false };
    }

    Showlogin = () => {
        this.setState({ showlogin: true })
        //let response =await fetch("login/login");
        //const json = await response.json();
        //console.log(json.length)
    }

    render() {

        return (<div>
            <Login showlogin={this.state.showlogin} />
            <Navbar style={{ backgroundColor: "#a9bacc" }} className="sticky-nav">
                <Navbar.Text style={{ paddingLeft: "5%" }}></Navbar.Text>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#dfss">About Us</Nav.Link>
                        <Nav.Link href="#sdfssss">Careers</Nav.Link>
                        <Nav.Link href="#sdf">Blog</Nav.Link>
                        <Nav.Link href="#sdfs">Partner With Us</Nav.Link>
                        <Nav.Link href="#sdfssrrss">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Button variant="outline-info loginButton" onClick={this.Showlogin}>Login</Button>
            </Navbar>
         
        </div>)

    }


}
export default header;