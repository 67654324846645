import React from 'react'
import { Helmet } from "react-helmet";
import Login from '../Login/Login'
import { Menu } from 'primereact/menu';
import { Link } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton, FacebookShareCount, FacebookIcon
} from "react-share";
const type = {facebook:0,twitter:1,linkden:2,instagram:3}
    class header extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showlogin: false };
        this.items = [
            {

                items: [
                    {
                        label: 'Home',
                        url: '/'
                    },
                    {
                        label: 'Contact Us',
                        url: '/#/Contact'
                    },

                    {
                        label: 'About',
                        url: '/#/About'
                    }
                    ,
                    {
                        label: "services",
                        url: '/#/Services/Company Formation in India'


                    }


                ]
            }

        ];


    }

    setActive = (name) => {

        if (name === this.props.menuName) {

            return "active"
        } else {
            return "";
        }

    }
    loginClose = () => {
        this.setState({ showlogin: false });

    }
    loginShow = () => {
        this.setState({ showlogin: true });

    }

    getUrl = (props,mediaType) => {
        let id = props.match.params.id

        if (mediaType === type.facebook) {

            if (id) {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/" + id
            } else {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/"
            }
        }
        else if (mediaType === type.twitter) {

            if (id) {
                return "https://twitter.com/intent/tweet?u=https://talkuscs.com/#/" + id
            } else {
                return "https://twitter.com/intent/tweet?u=https://talkuscs.com/#/"
            }
        }
        else if (mediaType === type.linkden) {

            if (id) {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/" + id
            } else {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/"
            }
        }
        else if (mediaType === type.instagram) {

            if (id) {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/" + id
            } else {
                return "https://www.facebook.com/sharer/sharer.php?u=https://talkuscs.com/#/"
            }
        }
        else {
            return "https://talkuscs.com/#/"

        }

        }
        getTitle = (props) => {
              if (props.match.params) {
                return props.match.params.id;
                } else {

                    return "TalkUs Corporate Solutions"
                }

        }
//            <Helmet>
//    <title>Company Secretary,Company Registration Kerala,Company Registration Kakkanad,Company Registration Infopark,Company Formation Kerala,LLP Registration Kerala,Digital Signature,Business Consultant, Cochin, Trivandrum, Kerala, India,IE Code,TDS,Tax Consultant,Accounts and Taxation</title>
//    <meta http-equiv="content-type" content="text/html; charset=windows-1252" />
//    <meta name="description" content="Company Secretary Company Registration in Kerala, Company Registration Kerala, Company Registration in India, LLP Registration, LLP Registration India, LLP Registration in kerala, Digital Signature,GST registration,TalkUs provides Company Registration service and LLP Registration service in kerala." />
//    <meta name="author" content="TalkUs Corporate Solutions" />
//    <meta name="keywords" content="Company Secretery,Company Registration,Online Company Registration,Company Secretary in Angamaly,New Company Formation in Kerala,Company Registration Kerala,New Company formation in Infopark cochin,New Company Registration in aluva,,llp registration in kerala,new company registration,formation of company,formation of a company,Company Registration Kerala, Company Registration in Kerala, LLP Registration Kerala, LLP Registration in Kerala, New Company Registration Kerala, Register company in Kerala, How to register company in kerala, company regsitration process kerala, Business Consultants Trivandrum, Business Consultants India, Business Consultants Kerala, trade marks kerala, e filing kerala, e filing india, digital signatures kerala, digital signature ernakulam, digital signature india, company registration india, company registration kerala,gst registration,gst registration in angamaly" />
//    <meta name="classification" content="Business" />
//    <meta itemprop="headline" content={this.getTitle(this.props.param)} />
//    <meta property="og:title" content="Company Secretary,Company Registration Kerala,LLP Registration Kerala,Digital Signature,IE Code,TDS,Tax Consultant,Accounts and Taxation" />
//    <meta property="og:type" content="article" />
//    <meta property="og:image" content="/static/media/slide-2.jpg" />
//    <meta property="og:url" content={"https://talkuscs.com/#/" + this.getTitle(this.props.param)} />
//    <meta itemprop="description" content="Welcome to Talkus Corporate Solutions. We provide specialized services to the entrepreneurs to establish their projected business in India as per the Companies Act and other Legal Compliances" />
//    <meta name="google-site-verification" content="chzDKllaqZQ__PntR2OF4mkGik8b38C0ty8yGgzSyUk" />
//    <meta name="google-site-verification" content="MmkSpYGVVLOE8MjwxS8Juue3R2kfs6IWqFxAhNf-GbE" />
//</Helmet>

        render() {
            console.log(this.props)
               return (<div>
            <Login showlogin={this.state.showlogin} onHide={this.loginClose} />
               

            <Menu model={this.items} popup ref={el => this.menu = el} id="popup_menu" />
                   <header id="header" className="fixed-top">
                      
                <div className="container d-flex align-items-center">

                    <button type="button" onClick={(event) => this.menu.toggle(event)} class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
                    <h1 className="logo mr-auto"><Link to="/"><span>Talk</span>Us</Link></h1>

                    <Link to="/" className="logo mr-auto"><img src="assets/img/logo.png" alt="" className="img-fluid" /></Link>
                    <div className="mobileNo"><p className="headerCall"><i className="icofont-phone"></i> +91 9846505914, 0484-2458331</p></div>
                    <nav className="nav-menu d-none d-lg-block">
                        <ul>
                            <li className={this.setActive("Home")}><Link to="/">Home</Link></li>

                            <li className={this.setActive("About")} ><Link to="/About">About</Link>

                            </li>

                            <li className="drop-down"><Link to="/Services/Company Formation in India">Services</Link>

                                <ul>
                                    <li className="drop-down"><Link to="/Services/Company Registration in Kerala">Company Regisration</Link>
                                        <ul>
                                            <li><Link to="/Services/Company Formation in India">Public Company Registration</Link></li>
                                            <li><Link to="/Services/Private Limited Company vs  Limited Liability Partnership">Private Company Registration</Link></li>
                                            <li><Link to="/Services/Nidhi Company Registration">Nidhi Company Registration</Link></li>
                                        </ul>
                                    </li>

                                    <li><Link to="/Services/ Limited Liability Partnership (LLP)">LLP Registration</Link></li>
                                    <li><Link to="/Services/TradeMark Registration">TradeMark Registration</Link></li>
                                    <li><Link to="/Services/Digital Signature">Digital Signature</Link></li>
                                    <li><Link to="/Services/GST registration in Ernakulam">GST Registration</Link></li>
                                    <li><Link to="/Services/Accounting and Taxation">Annual Filing</Link></li>
                                    <li><Link to="/Services/Accounting and Taxation">Accounting & Taxation</Link></li>
                                    <li><Link to="/Services/Project Report for Bank Loan">PROJECT REPORTS</Link></li>
                                    <li><Link to="/Services/Software Development">Websites & software solutions</Link></li>
                                </ul>

                            </li>

                            <li className={this.setActive("Contact")}><Link to="/Contact">Contact Us</Link></li>
                            <li ><Link to="#" onClick={this.loginShow}>Login</Link></li>
                        </ul>
                    </nav>

                    <div className="header-social-links">
                        <a href={this.getUrl(this.props.param, type.twitter)} target="_blank" className="twitter"><i className="icofont-twitter"></i></a>
                        <a href={this.getUrl(this.props.param, type.facebook)} target="_blank" className="facebook"><i className="icofont-facebook"></i></a>
                        <a href={this.getUrl(this.props.param, type.instagram)} className="instagram"><i className="icofont-instagram" target="_blank" ></i></a>
                        <a href={this.getUrl(this.props.param, type.linkden)} className="linkedin" target="_blank" ><i className="icofont-linkedin"></i></a>

                    </div>

                </div>

            </header>
        </div>)

    }


}
export default header;