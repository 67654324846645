import React, { Component } from "react"
import { ProgressSpinner } from 'primereact/progressspinner';
class loading extends React.Component {
    
    constructor(props) {
        super(props)

    }
    render() {

        return (
            <div style={{ width: "100%", textAlign: "center", zIndex: 10000, position: "absolute" }}>{this.props.loading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" /> : ''}
            
            </div>       
            );

    }
}
export default loading