import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Message from '../Common/Message'
import { Calendar } from 'primereact/calendar';
import './flexGrid.css';
import Loading from '../Loading';
import Moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import Search from '../Common/Search'
import { APIUrl } from "../Common/Api";
const VoucherType = [
    { label: 'Receipt', value: 2 },
    { label: 'Payment', value: 1 },

];
const PaymentStatus = [
    { label: 'Payed', value: 1 },
    { label: 'UnPayed', value: 2 },

];
let userData = "";
class voucherEntry extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '' },
            VoucherID: '',
            selectedRow: '',
            CompanyList: [],
            CompanyId: '',
            enableCompany: false
        }
    }
    componentWillMount() {

        if (this.props.selectedRow != '' && this.props.selectedRow != undefined) {
            this.fillCompany()
            this.setSelectedData();
        } else {

            this.clearData();
        }
    }

    setSelectedData = () => {
        
            let updateModel = this.state.dataModel;
        let voucherId = '';
        let CompanyId = [];
            let selectedrow = this.props.selectedRow
            for (var item in selectedrow) {
                if (selectedrow.hasOwnProperty(item)) {
                    if (item === "VoucherType") {
                        if (selectedrow[item] == 'Payment' || selectedrow[item] == 1) {
                            updateModel[item] = 1

                        } else {
                            updateModel[item] = 2

                        }
                    }
                    else if (item === "VoucherDate") {
                        updateModel[item] = this.formatdate(selectedrow[item]);
                    }

                    else if (item === "VoucherID") {
                        voucherId = selectedrow[item];
                    }

                    else if (item === "CompanyId") {
                        CompanyId = selectedrow[item];
                    }

                    else {
                        updateModel[item] = selectedrow[item]
                    }


                }
            }

        this.setState({ dataModel: updateModel, VoucherID: voucherId, selectedrow: '', CompanyId: CompanyId, enableCompany:true });

        

    }
    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }


    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }
    clearData = () => {
        this.fillCompany()
        let model = this.state.dataModel
        model['VoucherDate'] = new Date();
        model['VoucherType'] = ''
        model['Particulars'] = ''
        model['Amount'] = ''
        model['PaymentStatus'] = ''
        model['VoucherNo'] = ''
        model['Remarks'] = ''
        this.setState({ dataModel: model, VoucherID: '', loading: true, enableCompany:false })
        this.getVoucherNo(this.props.loginUser.companyList[0].CompanyId);
        
    }
    handleCompany = (e) => {
        this.setState({ CompanyId: e.value, loading: true})
        this.getVoucherNo(e.value);
    }

    fillCompany = () => {
        let jsonData = this.props.loginUser.companyList
        userData = this.props.loginUser;
        let list = [];
        for (var i = 0; i < jsonData.length; i++) {
            list.push({ 'label': jsonData[i].CompanyName, 'value': jsonData[i].CompanyId })
        }
        this.setState({ CompanyList: list, CompanyId: jsonData[0].CompanyId })
    }
    getVoucherNo = async (companyId) => {

        let url = APIUrl+'Receipt/GetMaxVOucherNo'

        var data = new FormData();
        data.append("CompanyId", companyId);

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {

            const jsonData = await response.json();
            if (jsonData != '') {
                let updateModel = this.state.dataModel
                updateModel["VoucherNo"] = jsonData
                this.setState({ dataModel: updateModel, loading: false })
            }

        }
        
    }
    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: false })
        let updateModel = this.state.dataModel
        let voucherId = 0;
        let CompanyId = [];
        for (var item in selectedrow) {
            if (selectedrow.hasOwnProperty(item)) {
                if (item === "VoucherType") {
                    if (selectedrow[item] == 'Payment') {
                        updateModel[item] = 1

                    } else {
                        updateModel[item] = 2

                    }
                }
                else if (item === "VoucherDate") {
                    updateModel[item] = this.formatdate(selectedrow[item]);
                }
                else if (item === "PaymentStatus") {
                    if (selectedrow[item] == 'Payed') {
                        updateModel[item] = 1

                    } else {
                        updateModel[item] = 2

                    }
                }
                else if (item === "CompanyId") {
                    //CompanyId.push(selectedrow[item]);
                    CompanyId = selectedrow[item];
                }

                else if (item === "VoucherID") {
                    voucherId = selectedrow[item];
                }

                else {
                    updateModel[item] = selectedrow[item]
                }


            }
        }

        this.setState({ dataModel: updateModel, VoucherID: voucherId, CompanyId: CompanyId, enableCompany:false });

    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'VoucherNo', header: 'VoucherNo', width: '15%' }, { field: 'VoucherDate', header: 'VoucherDate', width: '15%' }, { field: 'Particulars', header: 'Particulars', width: '50%' }, { field: 'PaymentStatus', header: 'Payment Status', width: '15%' }, , { field: 'VoucherType', header: 'VoucherType', width: '15%' }];
        search.query = "4";
        search.heading = "Find Voucher";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }
    saveVoucherEntry = () => {
       var data = new FormData();
        let voucherData = this.state;
        let model = voucherData.dataModel
        data.append('VoucherId', voucherData.VoucherID)
        data.append('VoucherNo', model.VoucherNo)
        data.append('VoucherDate', this.formatdateforSave(model.VoucherDate))
        data.append('VoucherType', model.VoucherType)
        data.append('Particulars', model.Particulars)
        data.append('Amount', model.Amount)
        data.append('PaymentStatus', model.PaymentStatus)
        data.append('CompanyId', voucherData.CompanyId)

        let remarks=''
        if (model.Remarks !== undefined) {
            remarks= model.Remarks
        }
        data.append('Remarks', remarks)
        //let data = {
        //    'VoucherId':voucherData.VoucherID,
        //    'VoucherNo': model.VoucherNo,
        //    'VoucherDate': this.formatdateforSave(model.VoucherDate),
        //    'VoucherType': model.VoucherType,
        //    'Particulars': model.Particulars,
        //    'Amount':model.Amount,
        //    'PaymentStatus': model.PaymentStatus,
        //    'CompanyId': voucherData.CompanyId,
        //    'Remarks': remarks
        //}

        this.setState({ loading: true })
        this.requestSave(data);
        return;

    }
    requestSave = async (data) => {
        let url = APIUrl+ 'VoucherEntry/InsertUpdateVoucher'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
                this.clearData();
            
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    isValidData = () => {
        let message = '';
        let data = this.state.dataModel

        if (data.VoucherNo == undefined || data.VoucherNo === '') {
            message = '* Voucher No not be blank!!'
        }
        if (data.VoucherType == undefined || data.VoucherType === '') {
            message += '\n* select voucher type!!'
        }

        if (data.Particulars == undefined || data.Particulars === '') {
            message += '\n* Particulars not be blank!!'
        }

        if (data.Amount == undefined || data.Amount === '') {
            message += '\n* Amount not be blank!!'
        }

        if (data.PaymentStatus == undefined || data.PaymentStatus === '') {
            message += '\n* Select Payment status!!'
        }




        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveVoucherEntry();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
        var data = new FormData();
        let voucherData = this.state;
        data.append('VoucherID', voucherData.VoucherID)
        this.requestDelete(data);
    }
    confirmDelete = () => {
        let voucherData = this.state;
        if (voucherData.VoucherID === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Voucher found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    requestDelete = async (data) => {
        let url = APIUrl+'VoucherEntry/DeleteVoucher'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
           
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
                this.clearData();
           
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }
       
    render() {


        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Clear" icon="pi pi-times" onClick={this.clearData} />
                <Button type="button" label="Close" onClick={this.props.onHide} />
                <Button type="button" label="Find" icon="pi pi-search" onClick={this.showFind} />
                <Button type="button" label="Delete" icon="pi pi-times" onClick={this.confirmDelete} />
            </div>
        );
        return (

            <Dialog footer={footer} header="Voucher Entry" visible={this.props.visible} style={{ width: '50vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.searchObject.visible ? <Search Company={this.props.loginUser.company} search={this.state.searchObject} /> : ''}
                <div style={{ minHeight: 400 }}>
                    {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                    {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                    <div className="p-grid">
                        <div className="p-col-3">Date:</div>
                        <div className="p-col"><Calendar value={this.state.dataModel['VoucherDate']} onChange={(e) => this.setState({ VoucherDate: this.formatdate(e.value) })} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange="2013:2030" dateFormat="dd/mm/yy" /></div>

                        <div className="p-col-2">VoucherNo:</div>
                        <div className="p-col-3"><InputText disabled name="VoucherNo" maxLength={99} value={this.state.dataModel['VoucherNo']} onChange={this.handleChange} style={{ width: "100%" }} /></div>


                    </div>
                    {this.props.loginUser.companyList.length > 1 ? <div className="p-grid"> <div className="p-col-3">Company:</div>< div className="p-col" ><Dropdown disabled={this.state.enableCompany} optionLabel="label" name="CompanyId" value={this.state.CompanyId} options={this.state.CompanyList} onChange={this.handleCompany} style={{ width: "100%" }} /></div></div> : ""}
                    <div className="p-grid">
                        <div className="p-col-3">Voucher Type:</div>
                        <div className="p-col"><Dropdown style={{ width: "100%" }} optionLabel="label" name="VoucherType" optionValue="value" value={this.state.dataModel['VoucherType']} options={VoucherType} onChange={this.handleChange} placeholder="Select voucher type" /></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">Particulars:</div>
                        <div className="p-col"><InputTextarea value={this.state.dataModel['Particulars']} onChange={this.handleChange} name="Particulars" rows={5} style={{ width: "100%" }} /></div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Amount:</div>
                        <div className="p-col"><InputText type="text" keyfilter="pint" value={this.state.dataModel['Amount']} maxLength={99} onChange={this.handleChange} name="Amount" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Payment Status:</div>
                        <div className="p-col"><Dropdown style={{ width: "100%" }} name="PaymentStatus" optionLabel="label" optionValue="value" value={this.state.dataModel['PaymentStatus']} options={PaymentStatus} onChange={this.handleChange} placeholder="Select Payment status" /></div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Remarks:</div>
                        <div className="p-col"><InputTextarea value={this.state.dataModel['Remarks']} onChange={this.handleChange} name="Remarks" rows={5} style={{ width: "100%" }} /></div>
                    </div>
                </div>
            </Dialog>
        )

    };
}

export default voucherEntry