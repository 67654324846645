import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Message from '../Common/Message'
import { Calendar } from 'primereact/calendar';
import './flexGrid.css';
import Loading from '../Loading';
import Moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import Search from '../Common/Search'
import { Editor } from 'primereact/editor';
import { APIUrl } from "../Common/Api";
 const Category = [
    { label:  'Our Services', value: 1 },
    { label: 'Informations', value: 2 },
    { label: 'News', value: 3 },
     { label: 'Query Post', value: 4 }
];

class contentEditor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            searchObject: { query: '', columns: [], continueFunction: '', heading: '', visible: false, onHide: '' },
            ContentId: ''
            
        }
    }
    componentWillMount() {
     this.clearData();
    }

 
    formatdate = (value) => {
        const formated = Moment(value, 'DD/MM/YYYY').toDate();
        return formated;

    }
    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
        var test = this.state.dataModel;
    }
    clearData = () => {
        let model = this.state.dataModel
        model['PostDate'] = new Date();
        model['Subject'] = ''
        model['Category'] = ''
        model['ShortContent'] = ''
        model['Content'] = ''
        this.setState({ dataModel: model, ContentId: '' })
               
    }
 
    findResult = (selectedrow) => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search, loading: false })
        let updateModel = this.state.dataModel
        let contentId = 0;
        for (var item in selectedrow) {
            if (selectedrow.hasOwnProperty(item)) {
                if (item === "PostDate") {
                    updateModel[item] = this.formatdate(selectedrow[item]);
                }

                else if (item === "ContentId") {
                    contentId = selectedrow[item];
                }

                else if (item === "Category") {
                    if (selectedrow[item] == 'Our Services') {
                        updateModel[item] = 1
                    }
                    else if (selectedrow[item] == 'Informations') {
                        updateModel[item] = 2
                    }

                    else if (selectedrow[item] == 'News') {
                        updateModel[item] = 3
                    }
                    else if (selectedrow[item] == 'Query Post') {
                        updateModel[item] = 4
                    }
                                       
                }

                else {
                    updateModel[item] = selectedrow[item]
                }


            }
        }

        this.setState({ dataModel: updateModel, ContentId: contentId });

    }
    messageHide = () => {
        this.setState({ messageVisible: false })

    }
    showFind = () => {

        let search = this.state.searchObject
        search.columns = [{ field: 'PostDate', header: 'Post date', width: '15%' }, { field: 'Subject', header: 'Subject', width: '70%' }, { field: 'Category', header: 'Category', width: '15%' }];
        search.query = "3";
        search.heading = "Find Voucher";
        search.visible = true;
        search.onHide = this.SearchHide;
        search.continueFunction = this.findResult
        this.setState({ searchObject: search })
    }
    SearchHide = () => {
        let search = this.state.searchObject
        search.visible = false;
        this.setState({ searchObject: search })
    }
    formatdateforSave = (value) => {
        if (typeof value === 'object') {
            return Moment(value).format('MM/DD/YYYY');
        }
        else {
            return value;
        }
    }
    saveContentData = () => {
        var data = new FormData();
        let contentData = this.state;
        let model = contentData.dataModel
        data.append('ContentId', contentData.ContentId)
        data.append('Subject', model.Subject)
        data.append('PostDate', this.formatdateforSave(model.PostDate))
        data.append('Category', model.Category)
        data.append('ShortContent', model.ShortContent)
        data.append('Content', model.Content)

        //let data = {
        //    'ContentId': contentData.ContentId,
        //    'Subject': model.Subject,
        //    'PostDate': this.formatdateforSave(model.PostDate),
        //    'Category': model.Category,
        //    'ShortContent': model.ShortContent,
        //    'Content': model.Content

        //}
        this.setState({ loading: true })
        this.requestSave(data);
        return;

    }
    requestSave = async (data) => {
        let url = APIUrl+'ContentEditor/InsertUpdateContentEditor'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
           
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data successfully saved", messageVisible: true, loading: false });
                this.clearData();
           
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data saving failed", messageVisible: true, loading: false });

        }
    }
    isValidData = () => {
        let message = '';
        let data = this.state.dataModel

        if (data.Subject == undefined || data.Subject === '') {
            message = '* Subject No not be blank!!'
        }
        if (data.ShortContent == undefined || data.ShortContent === '') {
            message += '\n* ShortContent not be blanke!!'
        }

        if (data.Category == undefined || data.Category === '') {
            message += '\n* Please select category!!'
        }

        if (data.Content == undefined || data.Content === '') {
            message += '\n* Content not be blank!!'
        }

        
        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveContentData();
    }
    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false, loading: true })
        var data = new FormData();
        let contentData = this.state;
        data.append('ContentId', contentData.ContentId)
        this.requestDelete(data);
    }
    confirmDelete = () => {
        let voucherData = this.state;
        if (voucherData.VoucherID === '') {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "No Voucher found for deletion !!.", messageVisible: true, loading: false });
        } else {
            this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
        }
    }
    requestDelete = async (data) => {
        let url = APIUrl+'ContentEditor/DeleteContent'

        const requestOptions = {
            method: 'POST',
            body: data
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {
            const jsonData = await response.json();
            if (jsonData === 'Success') {
                this.setState({ messageHeader: "Message", messageStatus: true, errorMessage: "Data deleted successfully", messageVisible: true, loading: false });
                this.clearData();
            }

            else {
                this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

            }
        }
        else {
            this.setState({ messageHeader: "Error", messageStatus: false, errorMessage: "Data deletion failed !!.", messageVisible: true, loading: false });

        }
    }
    setContent = (data) => {
        let updateModel = this.state.dataModel;
        updateModel["Content"] = data;
        this.setState({ dataModel: updateModel });


    }   
    render() {


        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Clear" icon="pi pi-times" onClick={this.clearData} />
                <Button type="button" label="Close" onClick={this.props.onHide} />
                <Button type="button" label="Find" icon="pi pi-search" onClick={this.showFind} />
                <Button type="button" label="Delete" icon="pi pi-times" onClick={this.confirmDelete} />
            </div>
        );
        return (

            <Dialog footer={footer} header="Content Entry" visible={this.props.visible} style={{ width: '80vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.searchObject.visible ? <Search Company={this.props.loginUser.company} search={this.state.searchObject} /> : ''}
                <div style={{ minHeight: 400 }}>
                    {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                    {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                    <div className="p-grid">
                        <div className="p-col-3">Date:</div>
                        <div className="p-col"><Calendar value={this.state.dataModel['PostDate']} onChange={(e) => this.setState({ PostDate: this.formatdate(e.value) })} showIcon={true} monthNavigator={true} yearNavigator={true} yearRange="2013:2030" dateFormat="dd/mm/yy" /></div>
                        
                    </div>
                                        

                    <div className="p-grid">
                        <div className="p-col-3">Subject:</div>
                        <div className="p-col"><InputText type="text" value={this.state.dataModel['Subject']} maxLength={99} onChange={this.handleChange} name="Subject" style={{ width: "100%" }} /></div>

                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Category:</div>
                        <div className="p-col"><Dropdown style={{ width: "100%" }} name="Category" optionLabel="label" optionValue="value" value={this.state.dataModel['Category']} options={Category} onChange={this.handleChange} placeholder="Select Category" /></div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col-3">Short Content:</div>
                        <div className="p-col"><InputTextarea value={this.state.dataModel['ShortContent']} onChange={this.handleChange} name="ShortContent" rows={5} style={{ width: "100%" }} /></div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-3">Content:</div>
                        <div className="p-col"><Editor style={{ height: '320px' }} value={this.state.dataModel['Content']} onTextChange={(e) => this.setContent(e.htmlValue)} /></div>
                    </div>
                </div>
            </Dialog>
        )

    };
}

export default contentEditor