import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import '../css/primereact.css';
import Loading from '../Loading';
import Message from '../Common/Message'
import AdminHome from '../AdminLayout/AdminHome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Login';
import { Route, Redirect } from "react-router-dom";
import { APIUrl } from '../Common/Api'
class login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            model: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false
        }
    }

      handleChange = (e) => {

        let updateModel = this.state.model
        updateModel[e.target.name] = e.target.value
        this.setState({ model: updateModel})

    }

    componentDidUpdate(nextprops) {
        if (this.props.user != nextprops.user) {

            if (this.props.isLogin) {
                // window.location.href = "/Admin";
            }
            else {
                if (this.props.user.message !== '') {

                    this.setState({ messageVisible: true, errorMessage: "Invalid Username/Password", messageStatus: false, messageHeader: 'Error', loading: false });
                }
                
            }

        }
    }

    requestLogin = async (data) => {

        this.props.requestLogin(data);

        return;
        const requestOptions1 = {
            method: 'POST',
          //  body: JSON.stringify(data)
           data: { user: data }
        };




        // let url = '/api/Login/UserLogin'
        let url = APIUrl + "Login/UserLogin"
        //    const requestOptions = {
        //    method: 'POST',
        //        body: "test",
        //};



        let response = await fetch(url, requestOptions1)
      
        if (response.status == 200) {

             const jsonData = await response.json();
            this.setState({ loading: false });
            this.props.onHide();
            window.location.href = "/Admin";
                   }
        else {
            this.setState({ messageVisible: true, errorMessage: "Invalid Username/Password", messageStatus: false, messageHeader: 'Error', loading: false });

        }
    }
    submit = () => {


        var data = new FormData();
        let updateModel = this.state.model
        data.append('UserName', updateModel.username)
        data.append('Password', updateModel.password)

        //let mData = { 'UserName': updateModel.username, 'Password': updateModel.password}

        this.requestLogin(data);
        return;

    }

    messageHide = () => {

        this.setState({ messageVisible: false, messageConfirm: false })
    }

    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false })

    }
    handleClose = () => {

        this.props.onHide();
    }
    render() {

        return (
            <Route exact path="/">{this.props.isLogin ? <Redirect to={{ pathname: "/Admin",state: { user: this.props.user } }} /> :
                <div className="content-section implementation dialog-demo">
                    <Dialog visible={this.props.showlogin} style={{ width: '60%' }} modal={true} onHide={this.props.onHide}>
                        {this.props.loading ? < Loading loading={this.props.loading} /> : ''}
                        {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={false} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                        {this.props.showAdmin ? <AdminHome /> : ''}
                        <form>
                            <div className="form-group">
                                <label>UserName</label>
                                <input type="email" name="username" className="form-control" placeholder="Enter email" onChange={this.handleChange} />
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" name="password" className="form-control" placeholder="Enter password" onChange={this.handleChange} />
                            </div>

                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                    <label style={{ marginLeft: "30%", color:"red" }}>{this.props.message}</label>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary btn-block" onClick={this.submit} >Submit</button>
                            <p className="forgot-password text-right">
                                <a href="#">Forgot password?</a>
                            </p>
                        </form>
                    </Dialog> </div>}
            </Route>
        );
    };

}
//export default login
export default connect(
    state => state.login,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(login);
