import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Message from '../Common/Message'
import './flexGrid.css';
import Loading from '../Loading';
import { APIUrl } from "../Common/Api";
class customer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataModel: [],
            messageVisible: false,
            errorMessage: '',
            messageStatus: true,
            messageHeader: '',
            loading: false,
            messageConfirm: false,
            CompanyId: '',

        }
    }

    handleChange = (e) => {
        let updateModel = this.state.dataModel
        updateModel[e.target.name] = e.target.value
        this.setState({ dataModel: updateModel });
    }

    componentWillMount() {
        this.setState({ CompanyId: this.props.CompanyId });
        if (this.props.status == false) {

            this.setCustomerValues();

        }
        else {

            this.setState({ dataModel: [] });
        }

    }
    clearModel = () => {
        let updateModel = this.state.dataModel;
        updateModel.id = '';
        updateModel.Name = '';
        updateModel.Address = '';
        updateModel.Phone = '';
        updateModel.Email = '';
        this.setState({ dataModel: updateModel })

    }
    saveCustomerDetails = async () => {


        var data = new FormData();
        let customerdata = this.state.dataModel
        let CompanyId = this.state.CompanyId;
        data.append('Name', customerdata.Name)
        data.append('CompanyId', CompanyId)
        data.append('Address', customerdata.Address)
        let phone = "";
        if (customerdata.Phone == undefined || customerdata.Phone == '') {
            data.append('Phone', "")
        } else {
            data.append('Phone', customerdata.Phone)


        }

        if (customerdata.Email == undefined || customerdata.Email == '') {
            data.append('Email', '')
        } else {
            data.append('Email', customerdata.Email)
        }

        if (customerdata.id != undefined) {
            data.append('Id', customerdata.id)
        }


        //let phone = "";
        //let email = "";
        //if (customerdata.Phone !== undefined || customerdata.Phone !== '')
        //{
        //    phone = customerdata.Phone
        //}
        //if (customerdata.Email !== undefined || customerdata.Email !== '')
        //{
        //    email = customerdata.Email;
        //}

        //let data = {
        //    'Name': customerdata.Name,
        //    'CompanyId': CompanyId,
        //    'Address': customerdata.Address,
        //    'Phone': phone,
        //    'Email': email,
        //    'Id': customerdata.id
        //}



        this.setState({ loading: true });

        var jsonData = data
        let url = APIUrl + 'Receipt/InsertUpdateCustomer'

        const requestOptions = {
            method: 'POST',
            body: jsonData
        };
        let response = await fetch(url, requestOptions)
        if (response.status == 200) {

            this.setState({ messageVisible: true, errorMessage: 'Data Saved Successfully', messageStatus: true, messageHeader: 'Message', loading: false, dataModel: [] });
            this.clearModel();


        }
        else if (response.status == 201) {
            this.setState({ messageVisible: true, errorMessage: 'Customer name exist.Choose another', messageStatus: false, messageHeader: 'Error', loading: false });
        }
        else {
            this.setState({ messageVisible: true, errorMessage: 'Data saving failed', messageStatus: false, messageHeader: 'Error', loading: false });
        }

    }

    isValidData = () => {
        let message = '';
        let data = this.state.dataModel;
        if (data.Name == undefined || data.Name === '') {
            message = '* Name not be blank!!'
        }

        if (data.Address == undefined || data.Address === '') {
            message += '\n* Billing Address not be blank!!'
        }


        if (message != '') {

            this.setState({ messageVisible: true, errorMessage: message, messageStatus: false, messageHeader: 'Error' })
            return false
        }

        this.saveCustomerDetails();


    }


    setCustomerValues = () => {

        for (var item in this.props.selectedRow) {
            if (this.props.selectedRow.hasOwnProperty(item)) {
                let updateModel = this.state.dataModel
                updateModel[item] = this.props.selectedRow[item]
                this.setState({ dataModel: updateModel });
            }
        }

    }
    messageHide = () => {

        this.setState({ messageVisible: false, messageConfirm: false })
    }

    continueConfirm = () => {
        this.setState({ messageVisible: false, messageConfirm: false })

    }
    confirmDelete = () => {

        this.setState({ messageVisible: true, errorMessage: 'Are you sure to delete the item?.', messageStatus: true, messageHeader: 'Confirm Message', messageConfirm: true });
    }

    render() {


        const footer = (
            <div>
                <Button type="button" label="Save" icon="pi pi-check" onClick={this.isValidData} />
                <Button type="button" label="Close" icon="pi pi-times" onClick={this.props.onHide} />
            </div>
        );
        return (

            <Dialog footer={footer} header="Customer" visible={this.props.visible} style={{ width: '50vw' }} modal={true} onHide={this.props.onHide}>
                {this.state.loading ? < Loading loading={this.state.loading} /> : ''}
                {this.state.messageVisible ? <Message continue={this.continueConfirm} confirm={this.state.messageConfirm} header={this.state.messageHeader} status={this.state.messageStatus} message={this.state.errorMessage} visible={this.state.messageVisible} onHide={this.messageHide} /> : ''}
                <div className="p-grid">
                    <div className="p-col-3">Customer Name:</div>
                    <div className="p-col"><InputText name="Name" maxLength={99} value={this.state.dataModel['Name']} onChange={this.handleChange} style={{ width: "100%" }} />
                    </div>

                </div>
                <div className="p-grid">
                    <div className="p-col-3">Billing Address:</div>
                    <div className="p-col"><InputTextarea value={this.state.dataModel['Address']} onChange={this.handleChange} name="Address" rows={5} style={{ width: "100%" }} /></div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3">Email:</div>
                    <div className="p-col"><InputText value={this.state.dataModel['Email']} maxLength={99} onChange={this.handleChange} name="Email" style={{ width: "100%" }} /></div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3">Phone:</div>
                    <div className="p-col"><InputText value={this.state.dataModel['Phone']} maxLength={99} onChange={this.handleChange} name="Phone" style={{ width: "100%" }} /></div>

                </div>

            </Dialog>
        )

    };
}

export default customer