import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Table, TableBody, TableCell, TableHeader, DataTableCell } from '@david.kucsai/react-pdf-table';
import './Style.css';
//import logo from '../Images/ReceiptLogo.jpg'
//import sign from '../Images/Sign.jpg'
//import logoCS from '../Images/BannerCS.jpg'
//import csSign from '../Images/SignCS.jpg'

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff"
    },
    textFont: {

        fontSize: "12px",
        fontFamily: "verdana"
    }


});

const setBankDetails = (props) => {

    if (props.bankDetails.length > 0) {
       
        if (props.bankDetails.length == 1) {
            return (
                <View>
                    <Text style={{ fontSize: 8, paddingTop: 5 }}>PAN No:{props.Pancard}</Text>
                <View style={{ display: "flex", flexDirection: "row", paddingTop: 5 }}>
                   
                    <View>
                            <Text style={{ fontSize: 8, textDecoration: "underline" }}>{props.bankDetails[0].Heading}</Text>
                            <Text style={{ fontSize: 8 }}> Account Number - {props.bankDetails[0].AccountNo}</Text>
                            <Text style={{ fontSize: 8 }}>Name - {props.bankDetails[0].Name}</Text>
                            <Text style={{ fontSize: 8 }}>Bank - {props.bankDetails[0].Bank}</Text>
                            <Text style={{ fontSize: 8 }}>Branch - {props.bankDetails[0].Branch}</Text>
                            <Text style={{ fontSize: 8 }}>Account Type - {props.bankDetails[0].AccountType}</Text>
                            <Text style={{ fontSize: 8 }}>IFSC Code - {props.bankDetails[0].IFSCCode}</Text>
                    </View>

                    {props.signature != "" ? <Image style={{ width: 161, height: 79, paddingLeft: 50 }} src={props.signature} /> : ""}

                    </View></View>
            )
        }
        else {
            return (
                <View>
                    <Text style={{ fontSize: 8, paddingTop: 5 }}>PAN No:{props.Pancard}</Text>
                < View style={{ display: "flex", flexDirection: "row", paddingTop: 5 }}>
                   
                    <View>
                        <Text style={{ fontSize: 8, textDecoration: "underline" }}>{props.bankDetails[0].Heading}</Text>
                        <Text style={{ fontSize: 8 }}> Account Number - {props.bankDetails[0].AccountNo}</Text>
                        <Text style={{ fontSize: 8 }}>Name - {props.bankDetails[0].Name}</Text>
                        <Text style={{ fontSize: 8 }}>Bank - {props.bankDetails[0].Bank}</Text>
                        <Text style={{ fontSize: 8 }}>Branch - {props.bankDetails[0].Branch}</Text>
                        <Text style={{ fontSize: 8 }}>Account Type - {props.bankDetails[0].AccountType}</Text>
                        <Text style={{ fontSize: 8 }}>IFSC Code - {props.bankDetails[0].IFSCCode}</Text>
                    </View>
                    <View style={{ marginLeft: 10 }}>
                        <Text style={{ fontSize: 8, textDecoration: "underline" }}>{props.bankDetails[1].Heading}</Text>
                        <Text style={{ fontSize: 8 }}> Account Number - {props.bankDetails[1].AccountNo}</Text>
                        <Text style={{ fontSize: 8 }}>Name - {props.bankDetails[1].Name}</Text>
                        <Text style={{ fontSize: 8 }}>Bank - {props.bankDetails[1].Bank}</Text>
                        <Text style={{ fontSize: 8 }}>Branch - {props.bankDetails[1].Branch}</Text>
                        <Text style={{ fontSize: 8 }}>Account Type - {props.bankDetails[1].AccountType}</Text>
                        <Text style={{ fontSize: 8 }}>IFSC Code - {props.bankDetails[1].IFSCCode}</Text>
                    </View>
                    {props.signature != "" ? <Image style={{ width: 161, height: 79, paddingLeft: 50 }} src={props.signature} /> : ""}

                    </View ></View >
            )
        }
        }
        else {
        return (
            <View>
            {props.signature != "" ? <Image style={{ width: 161, height: 79, paddingRight: 50 }} src={props.signature} /> : ""}
            </View >
        )
    }


}

export const MyDocument = (props) => {

    const FormatAmount = (value) => {
        if (value !== "" && value !== undefined) {
            return parseInt(value).toFixed(2);
        } else {
            return value;
        }

    }


    //<Image source={props.csEnabled ? logoCS : logo} />

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ margin: 20 }}>
                    <Table>
                        <TableHeader>
                            <TableCell >
                                <View style={{ margin: 20 }}>
                                    <Image src={props.logo} />
                                </View>



                            </TableCell>

                        </TableHeader>

                    </Table>
                    <Table style={{ border: "none" }}>
                        <TableHeader>
                            <TableCell style={{ borderStyle: "none" }} >
                                <View style={{ display: "flex", flexDirection: "row", textAlign: "left", paddingLeft: 10 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "normal" }}>Invoice No:</Text>
                                    <Text style={{ fontSize: 10, fontWeight: "normal" }}>&nbsp;{props.billNo}</Text>
                                </View>

                            </TableCell>
                            <TableCell>
                                <View style={{ display: "flex", flexDirection: "row", textAlign: "right", paddingRight: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "normal", paddingRight: 18 }}>Date:</Text>
                                    <Text style={{ fontSize: 10, fontWeight: "normal" }}>{props.receiptDate}</Text>
                                </View>
                            </TableCell>

                        </TableHeader>
                    </Table>
                    <Table>
                        <TableHeader>
                            <TableCell >
                                <View style={{ display: "flex", flexDirection: "row", textAlign: "left", paddingLeft: 10, margin: 40 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "normal" }}>To,</Text>
                                    <View style={{ textAlign: "left", paddingLeft: 10, marginTop: 5 }}>
                                        <Text style={{ fontSize: 10, fontWeight: "normal" }}>{props.Address}</Text>
                                    </View>
                                </View>

                            </TableCell>

                        </TableHeader>
                    </Table>
                    <Table style={{ marginTop: 5, marginBottom: 5 }} data={props.data}>
                        <TableHeader textAlign={"center"}>
                            <TableCell weighting={0.2}>
                                <Text style={{ fontSize: 10, fontWeight: "normal" }}>SI</Text>

                            </TableCell>
                            <TableCell>
                                <Text style={{ fontSize: 10, fontWeight: "normal" }}> Particulars</Text>

                            </TableCell>
                            <TableCell weighting={0.5}>
                                <Text style={{ fontSize: 10, fontWeight: "normal" }}> Amount</Text>

                            </TableCell>

                        </TableHeader>
                        <TableBody>

                            <DataTableCell style={{ textAlign: "center", fontSize: 10 }} weighting={0.2} getContent={(r) => r.SI} />
                            <DataTableCell style={{ textAlign: "left", fontSize: 10 }} getContent={(r) => r.Particulars} />
                            <DataTableCell style={{ textAlign: "left", fontSize: 10 }} weighting={0.5} getContent={(r) => FormatAmount(r.Amount)} />
                        </TableBody>
                    </Table>
                    <View style={{ marginTop: .4 }}>
                        <Table>
                            <TableHeader>
                                <TableCell style={{ textAlign: "right" }}>

                                    <View style={{ textAlign: "right", marginRight: 170, marginBottom: 80, marginTop: 10 }}>

                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <Text style={{ fontSize: 10, width: "100%" }}>Total:</Text>
                                            <Text style={{ fontSize: 10, paddingLeft: 7, textAlign: "left" }}>{props.total}</Text>
                                        </View>

                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <Text style={{ fontSize: 10, width: "100%" }}>Advance:</Text>
                                            <Text style={{ fontSize: 10, paddingLeft: 7, textAlign: "left" }}>{props.advance !== '' ? FormatAmount(props.advance) : '0.00'}</Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <Text style={{ fontSize: 10, width: "100%" }}>Discount:</Text>
                                            <Text style={{ fontSize: 10, paddingLeft: 7, textAlign: "left" }}>{props.discount !== '' ? FormatAmount(props.discount) : '0.00'}</Text>
                                        </View>

                                        <View style={{ display: "flex", flexDirection: "row" }}>
                                            <Text style={{ fontSize: 10, width: "100%" }}>Grand Total:</Text>
                                            <Text style={{ fontSize: 10, paddingLeft: 7, textAlign: "left" }}>{props.grandTotal}</Text>
                                        </View>

                                    </View>
                                </TableCell>

                            </TableHeader>
                        </Table>
                    </View>
                    {setBankDetails(props)}
                   


                </View>

            </Page>
        </Document>)

}


